import { useState, useEffect } from 'react';
import './Education.scss';
import cn from 'classnames';
import FirstScreen from '../../components/FirstScreen';
import MainPoints from '../../components/MainPoints';
import Trusted from '../../components/Trusted';
import StepsCards from '../../components/StepsCards';
import Reviews from '../../components/Reviews';
import Image from '../../assets/inner2.png';
import ImageMob from '../../assets/inner12.png';
import Step1 from '../../assets/steps/11.png';
import Step2 from '../../assets/steps/12.png';
import Step3 from '../../assets/steps/13.png';

const points = [
  {
    icon: (
      <svg viewBox='0 0 52 52' fill='none'>
        <path
          opacity='0.5'
          d='M8.76562 23.1732V32.9192C8.76562 36.7213 15.5013 42.6675 26.0011 42.6675C36.5009 42.6675 43.2366 36.848 43.2366 32.9192V22.8359L27.8834 29.1468C26.423 29.747 24.785 29.7475 23.3243 29.148L8.76562 23.1732Z'
          fill='#EE6328'
        />
        <path
          d='M22.539 8.4251L4.84314 15.7116C3.21307 16.3829 3.18559 18.6817 4.79917 19.3916L21.9109 26.9208C24.1998 27.9279 26.8044 27.9373 29.1004 26.9469L46.6859 19.361C48.3047 18.6627 48.2937 16.3633 46.6681 15.6806L29.4509 8.44937C27.242 7.52164 24.7544 7.51291 22.539 8.4251Z'
          fill='#EE6328'
        />
      </svg>
    ),
    title: 'University Partnerships',
    text: 'We collaborate with leading educational institutions worldwide to identify top talent across various disciplines for part-time internships and full-time roles.',
  },
  {
    icon: (
      <svg width='52' height='52' viewBox='0 0 52 52' fill='none'>
        <path
          opacity='0.5'
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M26 6.05274C26.3739 6.05245 26.7535 6.13743 27.1115 6.31719C27.6068 6.56592 28.0077 6.97384 28.2521 7.47781L33.3809 18.0522L44.8494 19.7478C46.222 19.9508 47.173 21.2475 46.9736 22.6442C46.8941 23.2004 46.6368 23.7144 46.2412 24.1067L37.9426 32.3377L39.9017 43.96C40.1361 45.351 39.218 46.6721 37.8509 46.9107C37.3065 47.0057 36.7466 46.9154 36.2577 46.6539L26 41.1666V6.05274Z'
          fill='#EE6328'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M26 6.05273V41.1662L15.7422 46.6534C14.5144 47.3102 12.9959 46.8299 12.3505 45.5807C12.0935 45.0832 12.0048 44.5134 12.0981 43.9595L14.0572 32.3373L5.75844 24.1065C4.76519 23.1213 4.74487 21.5035 5.71304 20.4928C6.09858 20.0904 6.60374 19.8285 7.15033 19.7477L18.6189 18.052L23.7479 7.47779C24.1851 6.57643 25.0748 6.05345 26 6.05273Z'
          fill='#EE6328'
        />
      </svg>
    ),
    title: 'Rigorous Screening',
    text: `Our multi-stage screening process assesses candidates' skills, motivation, and alignment with your company culture, whether for part-time or full-time positions.`,
  },
  {
    icon: (
      <svg width='52' height='52' viewBox='0 0 52 52' fill='none'>
        <g clip-path='url(#clip0_1135_3033)'>
          <path
            opacity='0.5'
            d='M43.3359 11.917C43.3359 10.1221 41.8809 8.66699 40.0859 8.66699C38.291 8.66699 36.8359 10.1221 36.8359 11.917V33.5837C36.8359 35.3786 38.291 36.8337 40.0859 36.8337C41.8809 36.8337 43.3359 35.3786 43.3359 33.5837V11.917Z'
            fill='#EE6328'
          />
          <path
            opacity='0.5'
            d='M32.5 22.75C32.5 20.9551 31.0449 19.5 29.25 19.5C27.4551 19.5 26 20.9551 26 22.75V33.5833C26 35.3783 27.4551 36.8333 29.25 36.8333C31.0449 36.8333 32.5 35.3783 32.5 33.5833V22.75Z'
            fill='#EE6328'
          />
          <path
            d='M10.8333 41.1667H43.3333C44.53 41.1667 45.5 42.1367 45.5 43.3333C45.5 44.53 44.53 45.5 43.3333 45.5H8.66667C7.47005 45.5 6.5 44.53 6.5 43.3333V8.66667C6.5 7.47005 7.47005 6.5 8.66667 6.5C9.86328 6.5 10.8333 7.47005 10.8333 8.66667V41.1667Z'
            fill='#EE6328'
          />
          <path
            opacity='0.5'
            d='M21.6641 27.083C21.6641 25.2881 20.209 23.833 18.4141 23.833C16.6191 23.833 15.1641 25.2881 15.1641 27.083V33.583C15.1641 35.3779 16.6191 36.833 18.4141 36.833C20.209 36.833 21.6641 35.3779 21.6641 33.583V27.083Z'
            fill='#EE6328'
          />
        </g>
        <defs>
          <clipPath id='clip0_1135_3033'>
            <rect width='52' height='52' fill='white' />
          </clipPath>
        </defs>
      </svg>
    ),
    title: 'Productive from Day One',
    text: 'We connect you with interns and early career professionals who have a proven track record of academic excellence and relevant real-world experience through internships, projects, and volunteering. ',
  },
  {
    icon: (
      <svg width='52' height='52' viewBox='0 0 52 52' fill='none'>
        <g clip-path='url(#clip0_1135_3011)'>
          <g clip-path='url(#clip1_1135_3011)'>
            <path
              opacity='0.5'
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M17.3385 6.5V7.58333C17.3385 9.37826 18.7936 10.8333 20.5885 10.8333H31.4219C33.2168 10.8333 34.6719 9.37826 34.6719 7.58333V6.5H39.0052C41.3984 6.5 43.3385 8.4401 43.3385 10.8333V45.5C43.3385 47.8932 41.3984 49.8333 39.0052 49.8333H13.0052C10.612 49.8333 8.67188 47.8932 8.67188 45.5V10.8333C8.67188 8.4401 10.612 6.5 13.0052 6.5H17.3385Z'
              fill='#EE6328'
            />
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M23.8333 4.33268C23.8333 3.13607 24.8034 2.16602 26 2.16602C27.1966 2.16602 28.1667 3.13607 28.1667 4.33268H31.4167C32.015 4.33268 32.5 4.81771 32.5 5.41602V7.58268C32.5 8.18099 32.015 8.66602 31.4167 8.66602H20.5833C19.985 8.66602 19.5 8.18099 19.5 7.58268V5.41602C19.5 4.81771 19.985 4.33268 20.5833 4.33268H23.8333Z'
              fill='#EE6328'
            />
            <path
              d='M23.8385 21.666H17.3385C16.1419 21.666 15.1719 22.6361 15.1719 23.8327C15.1719 25.0293 16.1419 25.9993 17.3385 25.9993H23.8385C25.0352 25.9993 26.0052 25.0293 26.0052 23.8327C26.0052 22.6361 25.0352 21.666 23.8385 21.666Z'
              fill='#EE6328'
            />
            <path
              d='M32.5052 30.334H17.3385C16.1419 30.334 15.1719 31.304 15.1719 32.5007C15.1719 33.6973 16.1419 34.6673 17.3385 34.6673H32.5052C33.7018 34.6673 34.6719 33.6973 34.6719 32.5007C34.6719 31.304 33.7018 30.334 32.5052 30.334Z'
              fill='#EE6328'
            />
          </g>
        </g>
        <defs>
          <clipPath id='clip0_1135_3011'>
            <rect width='52' height='52' fill='white' />
          </clipPath>
          <clipPath id='clip1_1135_3011'>
            <rect width='52' height='52' fill='white' />
          </clipPath>
        </defs>
      </svg>
    ),
    title: 'Paperwork is on us',
    text: `Don't want to hire directly? No problem. We can operate as a staffing agency, taking care of all the paperwork and regulations, so you can focus on growing your business.`,
  },
];

const steps = [
  {
    image: Step1,
    title: 'Discover bright, motivated talent',
    text: `Connect with promising interns and early career professionals across various disciplines. From part-time internships to full-time positions, find the perfect fit for your team's needs and grow with the future leaders and innovators of your industry.`,
  },
  {
    image: Step2,
    title: 'Relevant candidates in just two weeks',
    text: `We don't sell software; we ensure you get the right matches. Within two weeks, we'll provide you with interns and early career professionals who are genuinely interested and motivated to join your team and contribute to your organization's success.`,
  },
  {
    image: Step3,
    title: 'No Commitment, No Risk',
    text: `With Pioneers, you only pay if you hire an intern or early career professional. There's no long-term commitment or upfront fees, giving you the flexibility to scale your team as needed. Our competitive rates range from $25 to $35 per hour, ensuring you access top-tier talent at a fraction of the cost compared to traditional hiring methods.`,
  },
];

const EducationPage = ({ onFormClick }) => {
  const [isMobile, setIsMobile] = useState(false);

  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth <= 1200);
  };

  useEffect(() => {
    setIsMobile(window.innerWidth <= 1200);
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <div className='innerPage'>
      <FirstScreen
        text='Discover bright, motivated interns and early career professionals across various disciplines. Connect with future leaders and innovators to grow your team through part-time internships and full-time positions.'
        title={
          <>
            <span style={{ color: '#EE6328' }}>Find</span> the next generation of <span style={{ color: '#EE6328' }}>talent</span>
          </>
        }
        color='#EE6328'
        image={isMobile ? ImageMob : Image}
        onFormClick={onFormClick}
      />
      <MainPoints points={points} />
      <StepsCards cards={steps} color='#EE6328' />
      <div className='trustedHolder trustedHolderGap'>
        <Trusted />
      </div>
      <Reviews />
    </div>
  );
};

export default EducationPage;
