import { useState } from 'react';
import './Post.scss';
import Form from '../../components/Form';
import animation from '../../assets/loader.json';
import { useEffect } from 'react';
import Markdown from 'react-markdown';
import Posts from '../../assets/posts.json';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useParams } from 'react-router';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useLottie } from 'lottie-react';

const options = {
  animationData: animation,
  loop: true,
};

const BlogPost = () => {
  const [isFormOpen, setFormOpen] = useState(false);
  const id = decodeURIComponent(useParams().id);
  const navigate = useNavigate();
  const [postData, setPostData] = useState(Posts.find(item => item.id === id));
  const [isLoading, setIsLoading] = useState(false);
  const [content, setContent] = useState('');
  const { View } = useLottie(options);

  const fetchMarkdown = async () => {
    const post = Posts?.find(item => item.id === id);
    setPostData(post);
    try {
      const response = await fetch(post.content);
      if (response.ok) {
        const markdown = await response.text();
        setContent(markdown);
        setIsLoading(false);
      } else {
        console.error('Failed to fetch markdown content');
      }
    } catch (error) {
      console.error('Error fetching markdown content:', error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchMarkdown();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [id]);

  return (
    <div className='page'>
      <div className='contentHolder'>
        <Header onFormClick={() => setFormOpen(true)} />
        {isLoading ? (
          <div className='blogPageLoader'>{View}</div>
        ) : (
          <div className='blogPageContent'>
            <div className='blogPostHeader'>
              <h1 className={cn('blogPostTitle', postData.titlePosition === 'top' && 'blogPostTitleTop')}>{postData.name}</h1>
              <img src={postData.image} />
            </div>
            <div className='blogPostShare'></div>
            <div className='blogPostText'>
              <Markdown>{content}</Markdown>
            </div>
            <div className='blogPostTags'>
              {postData.tags.map(tag => (
                <span className='blogPostTag'>#{tag}</span>
              ))}
            </div>
            <div className='blogMoreTitle'>That Could Be Interesting...</div>
          </div>
        )}
        <div className='blogContent'>
          <div className='blogPosts'>
            {Posts.filter(item => item.id !== id)
              .slice(0, 3)
              .map(post => (
                <div className='blogPostCard' onClick={() => navigate(`/blog/${post.id}`)}>
                  <div className='blogPostCardImage'>
                    <img src={post.image_xs} />
                  </div>
                  <span className='blogPostCardTitle'>{post.name}</span>
                  <span className='blogPostCardDescription'>{post.description}</span>
                </div>
              ))}
          </div>
        </div>
        <Footer />
      </div>
      {isFormOpen && <Form onClose={() => setFormOpen(false)} />}
    </div>
  );
};

export default BlogPost;
