import './Products.scss';
import cn from 'classnames';
import { useState } from 'react';
import Screen1_1 from '../../assets/screens/1_1.png';
import Screen1_2 from '../../assets/screens/1_2.png';
import Screen1_3 from '../../assets/screens/1_3.png';
import Screen1_4 from '../../assets/screens/1_4.png';

import Screen2_1 from '../../assets/screens/2_1.png';
import Screen2_2 from '../../assets/screens/2_2.png';
import Screen2_3 from '../../assets/screens/2_3.png';
import Screen2_4 from '../../assets/screens/2_4.png';

import Screen3_1 from '../../assets/screens/3_1.png';
import Screen3_2 from '../../assets/screens/3_2.png';
import Screen3_3 from '../../assets/screens/3_3.png';
import Screen3_4 from '../../assets/screens/3_4.png';

import Screen4_1 from '../../assets/screens/4_1.png';
import Screen4_2 from '../../assets/screens/4_2.png';
import Screen4_3 from '../../assets/screens/4_3.png';
import Screen4_4 from '../../assets/screens/4_4.png';
import { useEffect } from 'react';

const Products = () => {
  const [activeProduct, setActiveProduct] = useState(0);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    setTimer(setTimeout(() => setActiveProduct(activeProduct > 2 ? 0 : activeProduct + 1), 5000));
  }, []);

  useEffect(() => {
    clearTimeout(timer);
    setTimer(setTimeout(() => setActiveProduct(activeProduct > 2 ? 0 : activeProduct + 1), 5000));
  }, [activeProduct]);

  return (
    <div className='productSection' id='product'>
      <h1 className='productTitle'>Product</h1>
      <div className='productText'>
        Pioneers platform streamlines hiring using AI and advanced analytics for precise candidate matching and vetting. It
        combines automated screening and AI-driven virtual interviews with human insights for optimal fit.
      </div>
      <div className='productHolder'>
        <div className='productButtonsHolder'>
          <div className='productButtons'>
            <span
              className={cn('productButton', activeProduct === 0 && 'productButton-active')}
              onClick={() => setActiveProduct(0)}
            >
              Intelligent Sourcing
            </span>
            <span
              className={cn('productButton', activeProduct === 1 && 'productButton-active')}
              onClick={() => setActiveProduct(1)}
            >
              Precision Screening
            </span>
            <span
              className={cn('productButton', activeProduct === 2 && 'productButton-active')}
              onClick={() => setActiveProduct(2)}
            >
              AI-Powered Interviews
            </span>
            <span
              className={cn('productButton', activeProduct === 3 && 'productButton-active')}
              onClick={() => setActiveProduct(3)}
            >
              In-depth Profile Analysis
            </span>
          </div>
        </div>
        <div className={cn('productScreenHided', activeProduct === 3 && 'productScreen-active')}></div>
        <div className={cn('productScreen', activeProduct === 0 && 'productScreen-active')}>
          <span className='productScreenTitle'>Intelligent Sourcing</span>
          <span className='productScreenText'>
            Leveraging cutting-edge algorithms, our platform goes beyond keyword matching to understand the context and nuanced
            requirements of each role, tapping into a wider talent pool and identifying candidates with the right fit.
          </span>
          <img
            className='productScreenImage'
            srcset={`${Screen1_2} 2000w, ${Screen1_2} 1400w, ${Screen1_3} 1024w, ${Screen1_4} 600w`}
            alt='screen'
          />
        </div>

        <div className={cn('productScreen', activeProduct === 1 && 'productScreen-active')}>
          <span className='productScreenTitle'>Precision Screening</span>
          <span className='productScreenText'>
            By automating the initial vetting process, we ensure that only the most qualified candidates move forward. Our system
            analyses hundreds of data points, delivering a shortlist based on the best match to your company's needs.
          </span>
          <img
            className='productScreenImage'
            srcset={`${Screen2_1} 1800w, ${Screen2_2} 1200w, ${Screen2_3} 800w, ${Screen2_4} 300w`}
            alt='screen'
          />
        </div>

        <div className={cn('productScreen', activeProduct === 2 && 'productScreen-active')}>
          <span className='productScreenTitle'>AI-Powered Interviews</span>
          <span className='productScreenText'>
            Our platform transforms the interview process with sophisticated AI technologies, conducting virtual interviews that
            not only save time but also employ behavioral analytics to thoroughly assess candidate fit. This innovative approach
            offers insights that are pivotal in selecting the ideal candidate for your team.
          </span>
          <img
            className='productScreenImage'
            srcset={`${Screen3_1} 1800w, ${Screen3_2} 1200w, ${Screen3_3} 800w, ${Screen3_4} 300w`}
            alt='screen'
          />
        </div>

        <div className={cn('productScreen', activeProduct === 3 && 'productScreen-active')}>
          <span className='productScreenTitle'>In-depth Profile Analysis</span>
          <span className='productScreenText'>
            We synthesize candidates' online professional footprints, past work experiences, and personal projects to offer a
            comprehensive evaluation, ensuring that the human touch in hiring is informed by data-driven insights.
          </span>
          <img
            className='productScreenImage'
            srcset={`${Screen4_1} 1800w, ${Screen4_2} 1200w, ${Screen4_3} 800w, ${Screen4_4} 300w`}
            alt='screen'
          />
        </div>
      </div>
    </div>
  );
};

export default Products;
