import './FullButton.scss';
import cn from 'classnames';
import { useEffect, useState } from 'react';

const FullButton = ({ color, onFormClick, buttonText }) => {
  return (
    <div className='fullButton' style={{ backgroundColor: color }}>
      <div className='content' onClick={onFormClick}>
        {buttonText}
        <svg className='fullButtonIcon' viewBox='0 0 36 26' fill='none'>
          <path d='M22.1326 1.06787C22.4336 5.12205 25.4284 13.1836 34.9998 12.9965' stroke='black' stroke-width='3.3' />
          <path d='M22.1326 24.9316C22.4336 20.8775 25.4284 12.8159 34.9998 13.003' stroke='black' stroke-width='3.3' />
          <path d='M35 12.9995L0.000244141 12.9995' stroke='black' stroke-width='3.3' />
        </svg>
      </div>
    </div>
  );
};

export default FullButton;
