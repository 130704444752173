import './MainPoints.scss';
import cn from 'classnames';
import { useEffect, useState } from 'react';

const MainPoints = ({ points, color }) => {
  return (
    <div className='mainPointsHolder'>
      {points.map((point, index) => (
        <div className='mainPoint' key={index}>
          <div className='mainPointIcon'>{point.icon}</div>
          <div className='mainPointTitle'>{point.title}</div>
          <div className='mainPointText'>{point.text}</div>
        </div>
      ))}
    </div>
  );
};

export default MainPoints;
