import { useState } from 'react';
import '../common.scss';
import cn from 'classnames';
import MainScreen from '../../components/MainScreen';
import Trusted from '../../components/Trusted';
import video from '../../assets/1.mp4';
import { useNavigate } from 'react-router-dom';
import Inner2 from '../../assets/inner11.png';
import Inner1 from '../../assets/inner12.png';
import Inner3 from '../../assets/inner31.jpg';
import Inner4 from '../../assets/inner41.png';
import Inner5 from '../../assets/inner51.png';
import Inner6 from '../../assets/inner61.jpg';

const MainPage = ({ onFormClick }) => {
  const navigate = useNavigate();

  return (
    <div>
      <MainScreen onFormClick={onFormClick} />
      <video src={`${video}#t=0.1`} className='videoBack' muted autoPlay loop playsinline />
      <div className='fullScreenSection missionSection'>
        <p className='secondCreenDetails'>
          We precisely <span>match companies with uniquely qualified professionals,</span> empowering businesses to achieve their
          HR goals. We believe <span>every candidate has a perfect role</span> where they can succeed, and our mission is to help{' '}
          <span>organizations find those ideal matches.</span>
        </p>
      </div>

      <div className='solutionsSectionHolder' id='company'>
        <h1 className='mainPageSectionTitle'>Solutions</h1>
        <div className='solutionsSection'>
          <div className='solutionCard'>
            <div className='solutionImage'>
              <img src={Inner1} />
            </div>
            <span className='solutionName'>Pioneers Education</span>
            <span className='solutionTitle'>Cultivating the Next Generation of Talent</span>
            <div className='solutionDescription'>
              Discover bright, motivated interns and early career professionals across various disciplines. Connect with future
              leaders and innovators to grow your team.
            </div>
            <div
              className='solutionButton'
              style={{ backgroundColor: '#EE6328' }}
              onClick={() => {
                window.scrollTo(0, 0);
                navigate('/education');
              }}
            >
              Get Started
            </div>
          </div>

          <div className='solutionCard'>
            <div className='solutionImage'>
              <img src={Inner2} />
            </div>
            <span className='solutionName'>Pioneers Engineers</span>
            <span className='solutionTitle'>Scaling Your Development Team with Top-Tier Talent</span>
            <div className='solutionDescription'>
              Scale your development team with top-tier, pre-screened talent. Access a global pool of skilled software engineers
              at competitive rates.
            </div>
            <div
              className='solutionButton'
              style={{ backgroundColor: '#4E75FF' }}
              onClick={() => {
                window.scrollTo(0, 0);
                navigate('/engineering');
              }}
            >
              Get Started
            </div>
          </div>

          <div className='solutionCard'>
            <div className='solutionImage'>
              <img src={Inner3} />
            </div>
            <span className='solutionName'>Pioneers Marketers</span>
            <span className='solutionTitle'>Connect with the Top Professional Marketers</span>
            <div className='solutionDescription'>
              Pioneers connects you with top-tier marketing pros, pre-screened for your convenience. Focus on growth while we
              handle the hiring.
            </div>
            <div
              className='solutionButton'
              style={{ backgroundColor: '#C759D9' }}
              onClick={() => {
                window.scrollTo(0, 0);
                navigate('/marketers');
              }}
            >
              Get Started
            </div>
          </div>

          <div className='solutionCard'>
            <div className='solutionImage'>
              <img src={Inner4} />
            </div>
            <span className='solutionName'>Pioneers Assistants</span>
            <span className='solutionTitle'>Find Your Perfect Executive Assistant</span>
            <div className='solutionDescription'>
              Pioneers links you with elite executive assistants, pre-screened for your convenience. Focus on productivity while
              we handle the hiring.
            </div>
            <div
              className='solutionButton'
              style={{ backgroundColor: '#FF7575' }}
              onClick={() => {
                window.scrollTo(0, 0);
                navigate('/assistants');
              }}
            >
              Get Started
            </div>
          </div>
          <div className='solutionCard'>
            <div className='solutionImage'>
              <img src={Inner5} />
            </div>
            <span className='solutionName'>Pioneers Nurses</span>
            <span className='solutionTitle'>Find Your Perfect Nursing Professional</span>
            <div className='solutionDescription'>
              Pioneers connects you with exceptional nursing talent, thoroughly screened for quality and reliability. Focus on
              delivering excellent patient care while we handle your staffing needs.
            </div>
            <div
              className='solutionButton'
              style={{ backgroundColor: '#71C348' }}
              onClick={() => {
                window.scrollTo(0, 0);
                navigate('/nurses');
              }}
            >
              Get Started
            </div>
          </div>

          <div className='solutionCard'>
            <div className='solutionImage'>
              <img src={Inner6} />
            </div>
            <span className='solutionName'>Pioneers Sales</span>
            <span className='solutionTitle'>Boost Your Revenue with Top Sales Professionals</span>
            <div className='solutionDescription'>
              Pioneers connects you with top-tier sales professionals, pre-screened to meet your specific business needs. Drive
              revenue growth while we handle the hiring process.
            </div>
            <div
              className='solutionButton'
              style={{ backgroundColor: '#15B0E1' }}
              onClick={() => {
                window.scrollTo(0, 0);
                navigate('/sales');
              }}
            >
              Get Started
            </div>
          </div>
        </div>
      </div>

      <div className='howWeHolder'>
        <h1 className='mainPageSectionTitle' style={{ textAlign: 'center' }}>
          How we work
        </h1>
        <div className='howWeItem'>
          <div className='howWePoint'>
            <span className='howWePointCount'>01.</span>
            <span className='howWePointTitle'>Unique matches tailored to your needs</span>
            <span className='howWePointText'>
              At Pioneers, we connect you with pre-vetted, highly motivated professionals who perfectly match your requirements.
              Don't have a job description? No problem. Simply tell us who you need, and we'll craft the perfect profile for you.
            </span>
          </div>
          <div className='howWePoint'>
            <span className='howWePointCount'>02.</span>
            <span className='howWePointTitle'>Affordable services powered by technology </span>
            <span className='howWePointText'>
              Unlike traditional agencies, our services are highly affordable, thanks to the cutting-edge technologies we use to
              automate the search process. This allows us to provide exceptional talent at a fraction of the cost.
            </span>
          </div>
          <div className='howWePoint'>
            <span className='howWePointCount'>03.</span>
            <span className='howWePointTitle'>No commitment, no risk </span>
            <span className='howWePointText'>
              With Pioneers, you only pay if you hire. There's no long-term commitment or upfront fees, giving you the flexibility
              to scale your team as needed.
            </span>
          </div>
          <div className='howWePoint'>
            <span className='howWePointCount'>04.</span>
            <span className='howWePointTitle'>We handle the paperwork and regulation </span>
            <span className='howWePointText'>
              Don't want to hire directly? No problem. We can operate as a staffing agency, taking care of all the paperwork and
              regulations, so you can focus on growing your business.
            </span>
          </div>
          <div className='howWePoint'>
            <span className='howWePointCount'>05.</span>
            <span className='howWePointTitle'>Interested and motivated candidates in just two weeks</span>
            <span className='howWePointText'>
              We don't sell software; we ensure you get the right matches. Within two weeks, we'll provide you with candidates who
              are genuinely interested and motivated to join your team.
            </span>
          </div>
        </div>
      </div>
      <div className='trustedHolder'>
        <Trusted />
      </div>
    </div>
  );
};

export default MainPage;
