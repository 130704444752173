import './Header.scss';
import Logo from '../../assets/NewLogo.svg';
import LogoText from '../../assets/Pioneers.svg';
import cn from 'classnames';
import { useEffect, useState } from 'react';

const Header = ({ onFormClick }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleScroll = () => {
    setIsMenuOpen(false);
  };

  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth <= 1200);
  };

  useEffect(() => {
    setIsMobile(window.innerWidth <= 1200);
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <div className={cn('headerOld')}>
      <div className='headerLogo'>
        <img src={Logo} alt='Logo' />
        <img src={LogoText} alt='Logo' />
      </div>
      {isMobile ? (
        <>
          {isMenuOpen ? (
            <div className='headerDetails'>
              <span className='headerContacts' onClick={onFormClick}>
                Contact Sales
              </span>
              <div className='headerMenu'>
                <a className='headerMenuItem' href='#product'>
                  Product <span>01.</span>
                </a>
                <a className='headerMenuItem' href='#company'>
                  Company <span>02.</span>
                </a>
                <a className='headerMenuItem' href='#pioneers'>
                  Pioneers Education <span>03.</span>
                </a>
                <a className='headerMenuItem' href='#responsibility'>
                  Corporate Responsibility <span>04.</span>
                </a>
                <a className='headerMenuItem' href='/'>
                  For clients <span>05.</span>
                </a>
              </div>
            </div>
          ) : (
            <span className='headerMenu' onClick={() => setIsMenuOpen(true)}>
              <svg width='32' height='33' viewBox='0 0 32 33' fill='none'>
                <path d='M2.3999 7.05762H30.1332' stroke='white' />
                <path d='M2.3999 13.4575H30.1332' stroke='white' />
                <path d='M2.3999 19.8574H30.1332' stroke='white' />
                <path d='M2.3999 26.2578H30.1332' stroke='white' />
              </svg>
            </span>
          )}
        </>
      ) : (
        <div className='headerDetails'>
          <span className='headerContacts' onClick={onFormClick}>
            Contact Sales
          </span>
          <div className='headerMenu'>
            <a className='headerMenuItem' href='#product'>
              Product <span>01.</span>
            </a>
            <a className='headerMenuItem' href='#company'>
              Company <span>02.</span>
            </a>
            <a className='headerMenuItem' href='#pioneers'>
              Pioneers Education <span>03.</span>
            </a>
            <a className='headerMenuItem' href='#responsibility'>
              Corporate Responsibility <span>04.</span>
            </a>
            <a className='headerMenuItem' href='/'>
              For clients <span>05.</span>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
