import { useState, useEffect } from 'react';
import './Nurses.scss';
import FirstScreen from '../../components/FirstScreen';
import Trusted from '../../components/Trusted';
import Image from '../../assets/inner5.png';
import ImageMob from '../../assets/inner51.png';
import FlagEng from '../../assets/flags/eng.png';
import FlagEsp from '../../assets/flags/esp.png';
import FlagHin from '../../assets/flags/hin.png';
import FlagSwa from '../../assets/flags/swa.png';
import Photo1 from '../../assets/reviews/rev17.jpg';
import Photo2 from '../../assets/reviews/rev16.jpg';
import Photo3 from '../../assets/reviews/rev15.jpg';
import Exp1 from '../../assets/experts/exp9.png';
import Exp2 from '../../assets/experts/exp10.png';
import Exp3 from '../../assets/experts/exp11.png';
import Exp4 from '../../assets/experts/exp12.png';
import ServiceCard from '../../components/ServiceCard';
import MainPoints from '../../components/MainPoints';

const points = [
  {
    icon: (
      <svg viewBox='0 0 52 52' fill='none'>
        <path
          opacity='0.5'
          d='M8.76562 23.1732V32.9192C8.76562 36.7213 15.5013 42.6675 26.0011 42.6675C36.5009 42.6675 43.2366 36.848 43.2366 32.9192V22.8359L27.8834 29.1468C26.423 29.747 24.785 29.7475 23.3243 29.148L8.76562 23.1732Z'
          fill='#71C348'
        />
        <path
          d='M22.539 8.4251L4.84314 15.7116C3.21307 16.3829 3.18559 18.6817 4.79917 19.3916L21.9109 26.9208C24.1998 27.9279 26.8044 27.9373 29.1004 26.9469L46.6859 19.361C48.3047 18.6627 48.2937 16.3633 46.6681 15.6806L29.4509 8.44937C27.242 7.52164 24.7544 7.51291 22.539 8.4251Z'
          fill='#71C348'
        />
      </svg>
    ),
    title: 'Exciting Opportunities',
    text: 'Pioneers ensures you’re placed in top-tier nursing roles, where your skills and experience are valued. We only work with facilities that provide opportunities for you to thrive and grow.',
  },
  {
    icon: (
      <svg width='52' height='52' viewBox='0 0 52 52' fill='none'>
        <path
          opacity='0.5'
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M26 6.05274C26.3739 6.05245 26.7535 6.13743 27.1115 6.31719C27.6068 6.56592 28.0077 6.97384 28.2521 7.47781L33.3809 18.0522L44.8494 19.7478C46.222 19.9508 47.173 21.2475 46.9736 22.6442C46.8941 23.2004 46.6368 23.7144 46.2412 24.1067L37.9426 32.3377L39.9017 43.96C40.1361 45.351 39.218 46.6721 37.8509 46.9107C37.3065 47.0057 36.7466 46.9154 36.2577 46.6539L26 41.1666V6.05274Z'
          fill='#71C348'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M26 6.05273V41.1662L15.7422 46.6534C14.5144 47.3102 12.9959 46.8299 12.3505 45.5807C12.0935 45.0832 12.0048 44.5134 12.0981 43.9595L14.0572 32.3373L5.75844 24.1065C4.76519 23.1213 4.74487 21.5035 5.71304 20.4928C6.09858 20.0904 6.60374 19.8285 7.15033 19.7477L18.6189 18.052L23.7479 7.47779C24.1851 6.57643 25.0748 6.05345 26 6.05273Z'
          fill='#71C348'
        />
      </svg>
    ),
    title: 'Flexibility for Your Lifestyle',
    text: `Whether you’re looking for short-term contracts, part-time gigs, or long-term placements, we’ve got you covered. We offer a wide range of flexible assignments that work with your schedule and preferences, giving you the freedom to balance work and life.`,
  },
  {
    icon: (
      <svg width='52' height='52' viewBox='0 0 52 52' fill='none'>
        <g clip-path='url(#clip0_1135_3033)'>
          <path
            opacity='0.5'
            d='M43.3359 11.917C43.3359 10.1221 41.8809 8.66699 40.0859 8.66699C38.291 8.66699 36.8359 10.1221 36.8359 11.917V33.5837C36.8359 35.3786 38.291 36.8337 40.0859 36.8337C41.8809 36.8337 43.3359 35.3786 43.3359 33.5837V11.917Z'
            fill='#71C348'
          />
          <path
            opacity='0.5'
            d='M32.5 22.75C32.5 20.9551 31.0449 19.5 29.25 19.5C27.4551 19.5 26 20.9551 26 22.75V33.5833C26 35.3783 27.4551 36.8333 29.25 36.8333C31.0449 36.8333 32.5 35.3783 32.5 33.5833V22.75Z'
            fill='#71C348'
          />
          <path
            d='M10.8333 41.1667H43.3333C44.53 41.1667 45.5 42.1367 45.5 43.3333C45.5 44.53 44.53 45.5 43.3333 45.5H8.66667C7.47005 45.5 6.5 44.53 6.5 43.3333V8.66667C6.5 7.47005 7.47005 6.5 8.66667 6.5C9.86328 6.5 10.8333 7.47005 10.8333 8.66667V41.1667Z'
            fill='#71C348'
          />
          <path
            opacity='0.5'
            d='M21.6641 27.083C21.6641 25.2881 20.209 23.833 18.4141 23.833C16.6191 23.833 15.1641 25.2881 15.1641 27.083V33.583C15.1641 35.3779 16.6191 36.833 18.4141 36.833C20.209 36.833 21.6641 35.3779 21.6641 33.583V27.083Z'
            fill='#71C348'
          />
        </g>
        <defs>
          <clipPath id='clip0_1135_3033'>
            <rect width='52' height='52' fill='white' />
          </clipPath>
        </defs>
      </svg>
    ),
    title: 'Hassle-Free Job Placement',
    text: 'Let us handle the details. From finding the perfect position to finalizing the paperwork, we make sure your job search is stress-free. All you have to do is show up and do what you do best - deliver exceptional care.',
  },
  {
    icon: (
      <svg width='52' height='52' viewBox='0 0 52 52' fill='none'>
        <g clip-path='url(#clip0_1135_3011)'>
          <g clip-path='url(#clip1_1135_3011)'>
            <path
              opacity='0.5'
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M17.3385 6.5V7.58333C17.3385 9.37826 18.7936 10.8333 20.5885 10.8333H31.4219C33.2168 10.8333 34.6719 9.37826 34.6719 7.58333V6.5H39.0052C41.3984 6.5 43.3385 8.4401 43.3385 10.8333V45.5C43.3385 47.8932 41.3984 49.8333 39.0052 49.8333H13.0052C10.612 49.8333 8.67188 47.8932 8.67188 45.5V10.8333C8.67188 8.4401 10.612 6.5 13.0052 6.5H17.3385Z'
              fill='#71C348'
            />
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M23.8333 4.33268C23.8333 3.13607 24.8034 2.16602 26 2.16602C27.1966 2.16602 28.1667 3.13607 28.1667 4.33268H31.4167C32.015 4.33268 32.5 4.81771 32.5 5.41602V7.58268C32.5 8.18099 32.015 8.66602 31.4167 8.66602H20.5833C19.985 8.66602 19.5 8.18099 19.5 7.58268V5.41602C19.5 4.81771 19.985 4.33268 20.5833 4.33268H23.8333Z'
              fill='#71C348'
            />
            <path
              d='M23.8385 21.666H17.3385C16.1419 21.666 15.1719 22.6361 15.1719 23.8327C15.1719 25.0293 16.1419 25.9993 17.3385 25.9993H23.8385C25.0352 25.9993 26.0052 25.0293 26.0052 23.8327C26.0052 22.6361 25.0352 21.666 23.8385 21.666Z'
              fill='#71C348'
            />
            <path
              d='M32.5052 30.334H17.3385C16.1419 30.334 15.1719 31.304 15.1719 32.5007C15.1719 33.6973 16.1419 34.6673 17.3385 34.6673H32.5052C33.7018 34.6673 34.6719 33.6973 34.6719 32.5007C34.6719 31.304 33.7018 30.334 32.5052 30.334Z'
              fill='#71C348'
            />
          </g>
        </g>
        <defs>
          <clipPath id='clip0_1135_3011'>
            <rect width='52' height='52' fill='white' />
          </clipPath>
          <clipPath id='clip1_1135_3011'>
            <rect width='52' height='52' fill='white' />
          </clipPath>
        </defs>
      </svg>
    ),
    title: 'Trusted Nationwide Network',
    text: `With access to facilities across the country, Pioneers open the door to endless travel nursing opportunities. Our trusted network ensures you’ll find the perfect role that matches your skills and career goals.`,
  },
];

const marketersExperts = [
  {
    logo: <img src={Exp4} />,
    name: 'Ayesha K.',
    position: 'Registered Nurse',
    time: 'Full-time',
    skills: ['Patient Care 6 years', 'IV Therapy 5 years', 'Emergency Response 4 years'],
  },
  {
    logo: <img src={Exp1} />,
    name: 'Ravi S.',
    position: 'Nurse Practitioner',
    time: 'Full-time',
    skills: ['Chronic Disease Management 7 years', 'Patient Assessments 7 years', 'Family Health 5 years'],
  },

  {
    logo: <img src={Exp2} />,
    name: 'Nia W.',
    position: 'Clinical Nurse Specialist',
    time: 'Part-time',
    skills: ['Critical Care 5 years', 'Patient Education 4 years', 'Patient Assessments 5 years '],
  },

  {
    logo: <img src={Exp3} />,
    name: 'Omar A.',
    position: 'Surgical Nurse',
    time: 'Full-time',
    skills: ['Surgical Assistance 6 years', 'Wound Care 5 years', 'Medication Administration 4 years'],
  },
];

const MarketersPage = ({ onFormClick }) => {
  const [isMobile, setIsMobile] = useState(false);

  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth <= 1200);
  };

  useEffect(() => {
    setIsMobile(window.innerWidth <= 1200);
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <div className='innerPage'>
      <FirstScreen
        title={
          <>
            Find Your Perfect <span style={{ color: '#71C348' }}>Role in Healthcare</span>
          </>
        }
        text='At Pioneers, we connect Healthcare Professionals with exciting opportunities. Focus on providing exceptional patient care, while we take care of finding your next adventure!'
        color='#71C348'
        image={isMobile ? ImageMob : Image}
        onFormClick={() => window.open('https://app.pioneers-education.com/healthcare/apply', '_blank')}
        buttonText='Join Our Team'
        withTumbler
      />
      <div className='marketersServices'>
        <span className='mainPageSectionTitle'>Join Our Team of Healthcare Experts</span>
        <div className='nursingServicesApplyHolder'>
          <div>
            <span className='serviceCardTitle'>Explore 1,000+ Healthcare Jobs</span>
            <span className='serviceCardText'>
              Discover your dream job in top healthcare facilities. We offer a wide range of positions across specialties and
              locations. Start your journey to a new career today!
            </span>
          </div>
          <span
            className='serviceCardButton'
            onClick={() => window.open('https://app.pioneers-education.com/healthcare/jobs', '_blank')}
          >
            See All Jobs{' '}
            <svg width='16' height='16' viewBox='0 0 16 16' fill='none'>
              <path
                d='M13.0043 4.76038L2.2647 15.5L0.5 13.7353L11.2396 2.99567H1.77382V0.5H15.5V14.2262H13.0043V4.76038Z'
                fill='black'
              />
            </svg>
          </span>
        </div>
        <div className='marketersServicesRow'>
          <ServiceCard
            icon={
              <svg width='52' height='52' viewBox='0 0 52 52'>
                <g clip-path='url(#clip0_1345_2807)'>
                  <path
                    opacity='0.5'
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M11.2481 43.6534L29.5352 27.1122C30.0997 26.6016 30.1582 25.713 29.6658 25.1275C29.6262 25.0805 29.5836 25.0362 29.5383 24.995L11.2513 8.3495C10.6883 7.83701 9.83121 7.89488 9.33701 8.47874C9.11978 8.73538 9 9.0652 9 9.40668V42.5933C9 43.3702 9.60731 44 10.3565 44C10.6843 44 11.001 43.8769 11.2481 43.6534Z'
                    fill='#71C348'
                  />
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M27.3552 43.6534L46.513 27.1122C47.1045 26.6016 47.1657 25.713 46.6498 25.1275C46.6084 25.0805 46.5638 25.0362 46.5164 24.995L27.3585 8.3495C26.7687 7.83701 25.8708 7.89488 25.3531 8.47874C25.1255 8.73538 25 9.0652 25 9.40668V42.5933C25 43.3702 25.6362 44 26.4211 44C26.7645 44 27.0963 43.8769 27.3552 43.6534Z'
                    fill='#71C348'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_1345_2807'>
                    <rect width='52' height='52' fill='white' />
                  </clipPath>
                </defs>
              </svg>
            }
            title='Clinical Practitioners'
            text='Ready to embark on your next adventure? Our clinical practitioners deliver specialized care in dynamic healthcare environments across the country. From advanced practice providers to physicians, your expertise will make a direct impact on patient outcomes.'
            tags={[
              'Patient Assessments',
              'Clinical Interventions',
              'Diagnostic Procedures',
              'Specialty Treatments',
              'Critical Care',
            ]}
          />
          <ServiceCard
            icon={
              <svg width='52' height='52' viewBox='0 0 52 52'>
                <g clip-path='url(#clip0_1345_2781)'>
                  <path
                    opacity='0.5'
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M17.3332 6.5V7.58333C17.3332 9.37826 18.7882 10.8333 20.5832 10.8333H31.4165C33.2114 10.8333 34.6665 9.37826 34.6665 7.58333V6.5H38.9998C41.3931 6.5 43.3332 8.4401 43.3332 10.8333V45.5C43.3332 47.8932 41.3931 49.8333 38.9998 49.8333H12.9998C10.6066 49.8333 8.6665 47.8932 8.6665 45.5V10.8333C8.6665 8.4401 10.6066 6.5 12.9998 6.5H17.3332Z'
                    fill='#71C348'
                  />
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M23.8333 4.33329C23.8333 3.13668 24.8034 2.16663 26 2.16663C27.1966 2.16663 28.1667 3.13668 28.1667 4.33329H31.4167C32.015 4.33329 32.5 4.81832 32.5 5.41663V7.58329C32.5 8.1816 32.015 8.66663 31.4167 8.66663H20.5833C19.985 8.66663 19.5 8.1816 19.5 7.58329V5.41663C19.5 4.81832 19.985 4.33329 20.5833 4.33329H23.8333Z'
                    fill='#71C348'
                  />
                  <path
                    d='M23.8332 21.6666H17.3332C16.1366 21.6666 15.1665 22.6367 15.1665 23.8333C15.1665 25.0299 16.1366 26 17.3332 26H23.8332C25.0298 26 25.9998 25.0299 25.9998 23.8333C25.9998 22.6367 25.0298 21.6666 23.8332 21.6666Z'
                    fill='#71C348'
                  />
                  <path
                    d='M32.4998 30.3334H17.3332C16.1366 30.3334 15.1665 31.3034 15.1665 32.5C15.1665 33.6967 16.1366 34.6667 17.3332 34.6667H32.4998C33.6965 34.6667 34.6665 33.6967 34.6665 32.5C34.6665 31.3034 33.6965 30.3334 32.4998 30.3334Z'
                    fill='#71C348'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_1345_2781'>
                    <rect width='52' height='52' fill='white' />
                  </clipPath>
                </defs>
              </svg>
            }
            title='Allied Health Professionals'
            text='Join our dedicated team of allied health professionals delivering essential specialized services. Your technical expertise and patient care skills keep healthcare facilities running smoothly.'
            tags={[
              'Diagnostic Testing',
              'Therapeutic Interventions',
              'Patient Care',
              'Specialized Treatments',
              'Rehabilitation Services',
            ]}
          />
        </div>
        <div className='marketersServicesRow'>
          <ServiceCard
            icon={
              <svg width='52' height='52' viewBox='0 0 52 52'>
                <g clip-path='url(#clip0_1345_2795)'>
                  <path
                    opacity='0.5'
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M11.1222 4.41149C26.1695 12.8098 31.5861 22.1917 27.3722 32.5573C16.6011 30.8433 11.1844 21.4614 11.1222 4.41149Z'
                    fill='#71C348'
                  />
                  <path
                    opacity='0.5'
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M40.6068 4.41149C25.5595 12.8098 20.1429 22.1917 24.3568 32.5573C35.1279 30.8433 40.5446 21.4614 40.6068 4.41149Z'
                    fill='#71C348'
                  />
                  <path
                    opacity='0.5'
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M26.0001 2.16669C17.1679 16.9634 17.1679 27.7968 26.0001 34.6667C34.4712 27.7968 34.4712 16.9634 26.0001 2.16669Z'
                    fill='#71C348'
                  />
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M13.7454 28.1667H38.2546C39.4512 28.1667 40.4212 29.1367 40.4212 30.3334C40.4212 30.5442 40.3904 30.754 40.3299 30.9559L35.5931 46.7452C35.0432 48.5781 33.3561 49.8334 31.4425 49.8334H20.5574C18.6438 49.8334 16.9567 48.5781 16.4068 46.7452L11.6701 30.9559C11.3262 29.8098 11.9766 28.6019 13.1228 28.2581C13.3248 28.1975 13.5345 28.1667 13.7454 28.1667Z'
                    fill='#71C348'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_1345_2795'>
                    <rect width='52' height='52' fill='white' />
                  </clipPath>
                </defs>
              </svg>
            }
            title='Healthcare Support Staff'
            text='Become part of our essential support team. Your dedication ensures smooth operations and exceptional patient experience across all healthcare settings.'
            tags={[
              'Patient Coordination',
              'Clinical Support Services',
              'Facility Operations',
              'Technical Support',
              'Administrative Services',
            ]}
          />
          <ServiceCard
            icon={
              <svg width='53' height='52' viewBox='0 0 53 52'>
                <g clip-path='url(#clip0_1345_2769)'>
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M19.8335 21.6666V41.1666L22.3308 41.9991C24.9819 42.8828 27.7582 43.3333 30.5527 43.3333H38.3381C41.4365 43.3333 44.1042 41.1463 44.7119 38.1081L46.3351 29.9917C47.0392 26.4716 44.7563 23.0472 41.2361 22.3432C40.6745 22.2309 40.1005 22.1933 39.529 22.2314L31.7502 22.75L32.5393 14.07C32.6877 12.4375 31.4846 10.9939 29.8522 10.8455C29.7629 10.8374 29.6732 10.8333 29.5835 10.8333C27.5672 10.8333 25.6988 11.8912 24.6614 13.6202L19.8335 21.6666Z'
                    fill='#71C348'
                  />
                  <path
                    opacity='0.5'
                    d='M13.3337 19.5H6.83366C5.63704 19.5 4.66699 20.47 4.66699 21.6667V41.1667C4.66699 42.3633 5.63704 43.3333 6.83366 43.3333H13.3337C14.5303 43.3333 15.5003 42.3633 15.5003 41.1667V21.6667C15.5003 20.47 14.5303 19.5 13.3337 19.5Z'
                    fill='#71C348'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_1345_2769'>
                    <rect width='52' height='52' fill='white' transform='translate(0.333496)' />
                  </clipPath>
                </defs>
              </svg>
            }
            title='Healthcare Leadership'
            text='Drive excellence and innovation in healthcare delivery. From clinical department directors to operational executives, your leadership will shape the future of healthcare organizations.'
            tags={[
              'Organizational Leadership',
              'Quality Improvement',
              'Strategic Planning',
              'Team Development',
              'Operational Excellence',
            ]}
          />
        </div>
      </div>
      <div className='marketersHow'>
        <div className='marketersHowHolder'>
          <span className='mainPageSectionTitle'>How it works</span>
          <div className='marketersHowSteps'>
            <div className='marketersHowStep'>
              <span className='marketersHowStepCount' style={{ background: '#71C348' }}>
                1
              </span>
              <span className='marketersHowStepTitle'>Tell us about your ideal assignment</span>
            </div>
            <span className='marketersHowArrow'>
              <svg width='56' height='38' viewBox='0 0 56 38'>
                <path d='M33.6714 1C34.1703 7.11679 39.1345 19.2798 54.9999 18.9975' stroke='#3F3F4D' stroke-width='6' />
                <path d='M33.6714 37C34.1703 30.8832 39.1345 18.7202 54.9999 19.0025' stroke='#3F3F4D' stroke-width='6' />
                <path d='M54.6304 18.9998H-0.000488281' stroke='#3F3F4D' stroke-width='6' />
              </svg>
            </span>
            <div className='marketersHowStep'>
              <span className='marketersHowStepCount' style={{ background: '#71C348' }}>
                2
              </span>
              <span className='marketersHowStepTitle'>
                <span
                  className='marketersHowStepTitleLink'
                  style={{ color: '#71C348' }}
                  onClick={() => window.open('https://app.pioneers-education.com/healthcare/apply', '_blank')}
                >
                  Get matched{' '}
                  <svg width='9' height='10' viewBox='0 0 9 10' fill='none'>
                    <path
                      d='M7.50259 3.05623L1.05882 9.5L0 8.44119L6.44378 1.9974H0.764291V0.5H9V8.73573H7.50259V3.05623Z'
                      fill='#71C348'
                    />
                  </svg>
                </span>
                <br />
                with a top medical facility
              </span>
            </div>
            <span className='marketersHowArrow'>
              <svg width='56' height='38' viewBox='0 0 56 38'>
                <path d='M33.6714 1C34.1703 7.11679 39.1345 19.2798 54.9999 18.9975' stroke='#3F3F4D' stroke-width='6' />
                <path d='M33.6714 37C34.1703 30.8832 39.1345 18.7202 54.9999 19.0025' stroke='#3F3F4D' stroke-width='6' />
                <path d='M54.6304 18.9998H-0.000488281' stroke='#3F3F4D' stroke-width='6' />
              </svg>
            </span>
            <div className='marketersHowStep'>
              <span className='marketersHowStepCount' style={{ background: '#71C348' }}>
                3
              </span>
              <span className='marketersHowStepTitle'>Start your next adventure!</span>
            </div>
          </div>
        </div>
      </div>

      <div className='trustedHolder trustedHolderGap marketersTrusted'>
        <Trusted type='nurses' />
      </div>
      <div className='reviewsHolder marketersReviews'>
        <div className='reviewCard'>
          <div className='reviewCardText'>
            "Pioneers really came through for me!{' '}
            <span>They found the perfect travel nursing job and the whole process was smooth and fast.</span> If you’re thinking
            about travel nursing, I highly recommend them!"
          </div>
          <div className='reviewCardData'>
            <div className='reviewCardPhoto'>
              <img src={Photo3} />
            </div>
            <div className='reviewCardNameData'>
              <span className='reviewCardName'>Sarah T, Registered Nurse</span>
            </div>
          </div>
        </div>

        <div className='reviewCard'>
          <div className='reviewCardText'>
            "I wasn’t sure about travel nursing at first, but Pioneers made it an easy decision.{' '}
            <span>They matched me with a great position, and the support throughout the process has been amazing.</span> I’m
            already on my second assignment and couldn’t be happier!"
          </div>
          <div className='reviewCardData'>
            <div className='reviewCardPhoto'>
              <img src={Photo2} />
            </div>
            <div className='reviewCardNameData'>
              <span className='reviewCardName'>David L, Licensed Practical Nurse</span>
            </div>
          </div>
        </div>

        <div className='reviewCard'>
          <div className='reviewCardText'>
            "Partnering with Pioneers was such a great choice. They really understood what I was looking for and{' '}
            <span>matched me with an assignment where I felt valued and appreciated.</span> The experience has been rewarding, and
            I’m excited to see where my next adventure takes me!"
          </div>
          <div className='reviewCardData'>
            <div className='reviewCardPhoto'>
              <img src={Photo1} />
            </div>
            <div className='reviewCardNameData'>
              <span className='reviewCardName'>Maria G, Travel RN</span>
            </div>
          </div>
        </div>
      </div>

      <div className='marketersExpertsHolder'>
        <span className='mainPageSectionTitle'>Our Team</span>

        <div className='marketersExpertsSliderHolder'>
          <div className='marketersExpertsSlider'>
            <div className='marketersExperts'>
              {marketersExperts.map(item => (
                <div className='marketersExpert'>
                  <div className='marketersExpertHeader'>
                    <span className='marketersExpertLogo'>{item.logo}</span>
                    <span className='marketersExpertName'>{item.name}</span>
                  </div>
                  <div className='marketersExpertDetails'>
                    <span className='marketersExpertDetail'>
                      <svg width='20' height='20' viewBox='0 0 20 20'>
                        <path
                          d='M12.4998 2.5C12.9601 2.5 13.3332 2.8731 13.3332 3.33333V5H17.4998C17.9601 5 18.3332 5.3731 18.3332 5.83333V16.6667C18.3332 17.1269 17.9601 17.5 17.4998 17.5H2.49984C2.0396 17.5 1.6665 17.1269 1.6665 16.6667V5.83333C1.6665 5.3731 2.0396 5 2.49984 5H6.6665V3.33333C6.6665 2.8731 7.0396 2.5 7.49984 2.5H12.4998ZM13.3332 6.66667H6.6665V15.8333H13.3332V6.66667ZM3.33317 6.66667V15.8333H4.99984V6.66667H3.33317ZM11.6665 4.16667H8.33317V5H11.6665V4.16667ZM14.9998 6.66667V15.8333H16.6665V6.66667H14.9998Z'
                          fill='white'
                        />
                      </svg>
                      {item.position}
                    </span>
                    <span className='marketersExpertDetail'>
                      <svg width='20' height='20' viewBox='0 0 20 20'>
                        <path
                          d='M9.99984 18.3334C5.39746 18.3334 1.6665 14.6024 1.6665 10.0001C1.6665 5.39771 5.39746 1.66675 9.99984 1.66675C14.6022 1.66675 18.3332 5.39771 18.3332 10.0001C18.3332 14.6024 14.6022 18.3334 9.99984 18.3334ZM9.99984 16.6667C13.6818 16.6667 16.6665 13.682 16.6665 10.0001C16.6665 6.31818 13.6818 3.33341 9.99984 3.33341C6.31794 3.33341 3.33317 6.31818 3.33317 10.0001C3.33317 13.682 6.31794 16.6667 9.99984 16.6667ZM10.8332 10.0001H14.1665V11.6667H9.1665V5.83341H10.8332V10.0001Z'
                          fill='white'
                        />
                      </svg>
                      {item.time}
                    </span>
                  </div>

                  <div className='marketersExpertSkills'>
                    {item.skills.map(skill => (
                      <span className='marketersExpertSkill'>
                        <svg width='20' height='20' viewBox='0 0 20 20'>
                          <path d='M8.5 12.1177L15 6L16 6.94118L8.5 14L4 9.76473L5 8.82356L8.5 12.1177Z' fill='white' />
                        </svg>
                        {skill}
                      </span>
                    ))}
                  </div>

                  <div
                    className='marketersExpertButton'
                    style={{ background: '#71C348' }}
                    onClick={() => window.open('https://app.pioneers-education.com/healthcare/apply', '_blank')}
                  >
                    Join Our Team
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className='marketersWhyHolder'>
        <span className='mainPageSectionTitle'>Why Choose Pioneers?</span>
        <div className='marketersWhyStats'>
          <div className='marketersWhyStat'>
            <span className='marketersWhyStatCount' style={{ color: '#71C348' }}>
              35%
            </span>
            <span className='marketersWhyStatText'>Faster Job Placement</span>
          </div>
          <div className='marketersWhyStat'>
            <span className='marketersWhyStatCount' style={{ color: '#71C348' }}>
              25%
            </span>
            <span className='marketersWhyStatText'>Higher Assignment Satisfaction</span>
          </div>
          <div className='marketersWhyStat'>
            <span className='marketersWhyStatCount' style={{ color: '#71C348' }}>
              1K+
            </span>
            <span className='marketersWhyStatText'>Job Opportunities</span>
          </div>
          <div className='marketersWhyStat'>
            <span className='marketersWhyStatCount' style={{ color: '#71C348' }}>
              24/7
            </span>
            <span className='marketersWhyStatText'>Trusted Support</span>
          </div>
        </div>

        <MainPoints points={points} />
      </div>
    </div>
  );
};

export default MarketersPage;
