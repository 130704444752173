import './StepsCards.scss';
import cn from 'classnames';
import { useEffect, useState } from 'react';

const StepsCards = ({ cards, color }) => {
  return (
    <div className='stepsCards'>
      {cards.map((card, index) => (
        <div className='stepsCard' key={index}>
          <div className='stepsCardImage'>
            <img src={card.image} />
          </div>
          <div className='stepsCardData'>
            <span className='stepsCardCount' style={{ color: color }}>
              0{index + 1}.
            </span>
            <span className='stepsCardTitle'>{card.title}</span>
            <span className='stepsCardText'>{card.text}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default StepsCards;
