import { useState, useEffect } from 'react';
import './Sales.scss';
import cn from 'classnames';
import FirstScreen from '../../components/FirstScreen';
import Trusted from '../../components/Trusted';
import Image from '../../assets/inner6.jpg';
import ImageMob from '../../assets/inner61.jpg';
import FlagEng from '../../assets/flags/eng.png';
import FlagFr from '../../assets/flags/fr.png';
import FlagGen from '../../assets/flags/ge.png';
import FlagMan from '../../assets/flags/man.png';
import FlagEsp from '../../assets/flags/esp.png';
import Photo1 from '../../assets/reviews/rev12.jpg';
import Photo2 from '../../assets/reviews/rev14.jpg';
import Photo3 from '../../assets/reviews/rev13.jpg';
import Exp1 from '../../assets/experts/exp13.png';
import Exp2 from '../../assets/experts/exp14.png';
import Exp3 from '../../assets/experts/exp15.png';
import Exp4 from '../../assets/experts/exp16.png';
import ServiceCard from '../../components/ServiceCard';
import MainPoints from '../../components/MainPoints';

const points = [
  {
    icon: (
      <svg viewBox='0 0 52 52' fill='none'>
        <path
          opacity='0.5'
          d='M8.76562 23.1732V32.9192C8.76562 36.7213 15.5013 42.6675 26.0011 42.6675C36.5009 42.6675 43.2366 36.848 43.2366 32.9192V22.8359L27.8834 29.1468C26.423 29.747 24.785 29.7475 23.3243 29.148L8.76562 23.1732Z'
          fill='#15B0E1'
        />
        <path
          d='M22.539 8.4251L4.84314 15.7116C3.21307 16.3829 3.18559 18.6817 4.79917 19.3916L21.9109 26.9208C24.1998 27.9279 26.8044 27.9373 29.1004 26.9469L46.6859 19.361C48.3047 18.6627 48.2937 16.3633 46.6681 15.6806L29.4509 8.44937C27.242 7.52164 24.7544 7.51291 22.539 8.4251Z'
          fill='#15B0E1'
        />
      </svg>
    ),
    title: 'Expertly Vetted Talent:',
    text: 'Our thorough multi-stage screening process guarantees that you connect with the most skilled and dedicated sales professionals. We rigorously assess their experience and capabilities to ensure you receive top-tier talent ready to drive your sales growth.',
  },
  {
    icon: (
      <svg width='52' height='52' viewBox='0 0 52 52' fill='none'>
        <path
          opacity='0.5'
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M26 6.05274C26.3739 6.05245 26.7535 6.13743 27.1115 6.31719C27.6068 6.56592 28.0077 6.97384 28.2521 7.47781L33.3809 18.0522L44.8494 19.7478C46.222 19.9508 47.173 21.2475 46.9736 22.6442C46.8941 23.2004 46.6368 23.7144 46.2412 24.1067L37.9426 32.3377L39.9017 43.96C40.1361 45.351 39.218 46.6721 37.8509 46.9107C37.3065 47.0057 36.7466 46.9154 36.2577 46.6539L26 41.1666V6.05274Z'
          fill='#15B0E1'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M26 6.05273V41.1662L15.7422 46.6534C14.5144 47.3102 12.9959 46.8299 12.3505 45.5807C12.0935 45.0832 12.0048 44.5134 12.0981 43.9595L14.0572 32.3373L5.75844 24.1065C4.76519 23.1213 4.74487 21.5035 5.71304 20.4928C6.09858 20.0904 6.60374 19.8285 7.15033 19.7477L18.6189 18.052L23.7479 7.47779C24.1851 6.57643 25.0748 6.05345 26 6.05273Z'
          fill='#15B0E1'
        />
      </svg>
    ),
    title: 'Flexibility',
    text: `Flexibility
    Whether you need a full-time Sales Representative, a part-time Account Manager, or specialized roles like Sales Analysts, we offer flexible staffing solutions tailored to your specific needs. We adjust to your requirements and timeline, providing the right talent when you need them.`,
  },
  {
    icon: (
      <svg width='52' height='52' viewBox='0 0 52 52' fill='none'>
        <g clip-path='url(#clip0_1135_3033)'>
          <path
            opacity='0.5'
            d='M43.3359 11.917C43.3359 10.1221 41.8809 8.66699 40.0859 8.66699C38.291 8.66699 36.8359 10.1221 36.8359 11.917V33.5837C36.8359 35.3786 38.291 36.8337 40.0859 36.8337C41.8809 36.8337 43.3359 35.3786 43.3359 33.5837V11.917Z'
            fill='#15B0E1'
          />
          <path
            opacity='0.5'
            d='M32.5 22.75C32.5 20.9551 31.0449 19.5 29.25 19.5C27.4551 19.5 26 20.9551 26 22.75V33.5833C26 35.3783 27.4551 36.8333 29.25 36.8333C31.0449 36.8333 32.5 35.3783 32.5 33.5833V22.75Z'
            fill='#15B0E1'
          />
          <path
            d='M10.8333 41.1667H43.3333C44.53 41.1667 45.5 42.1367 45.5 43.3333C45.5 44.53 44.53 45.5 43.3333 45.5H8.66667C7.47005 45.5 6.5 44.53 6.5 43.3333V8.66667C6.5 7.47005 7.47005 6.5 8.66667 6.5C9.86328 6.5 10.8333 7.47005 10.8333 8.66667V41.1667Z'
            fill='#15B0E1'
          />
          <path
            opacity='0.5'
            d='M21.6641 27.083C21.6641 25.2881 20.209 23.833 18.4141 23.833C16.6191 23.833 15.1641 25.2881 15.1641 27.083V33.583C15.1641 35.3779 16.6191 36.833 18.4141 36.833C20.209 36.833 21.6641 35.3779 21.6641 33.583V27.083Z'
            fill='#15B0E1'
          />
        </g>
        <defs>
          <clipPath id='clip0_1135_3033'>
            <rect width='52' height='52' fill='white' />
          </clipPath>
        </defs>
      </svg>
    ),
    title: 'Hassle-Free Hiring',
    text: 'From initial recruitment to final placement, we manage every aspect of the hiring process. Pioneers handles all the details, allowing you to focus on achieving your sales goals.',
  },
  {
    icon: (
      <svg width='52' height='52' viewBox='0 0 52 52' fill='none'>
        <g clip-path='url(#clip0_1135_3011)'>
          <g clip-path='url(#clip1_1135_3011)'>
            <path
              opacity='0.5'
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M17.3385 6.5V7.58333C17.3385 9.37826 18.7936 10.8333 20.5885 10.8333H31.4219C33.2168 10.8333 34.6719 9.37826 34.6719 7.58333V6.5H39.0052C41.3984 6.5 43.3385 8.4401 43.3385 10.8333V45.5C43.3385 47.8932 41.3984 49.8333 39.0052 49.8333H13.0052C10.612 49.8333 8.67188 47.8932 8.67188 45.5V10.8333C8.67188 8.4401 10.612 6.5 13.0052 6.5H17.3385Z'
              fill='#15B0E1'
            />
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M23.8333 4.33268C23.8333 3.13607 24.8034 2.16602 26 2.16602C27.1966 2.16602 28.1667 3.13607 28.1667 4.33268H31.4167C32.015 4.33268 32.5 4.81771 32.5 5.41602V7.58268C32.5 8.18099 32.015 8.66602 31.4167 8.66602H20.5833C19.985 8.66602 19.5 8.18099 19.5 7.58268V5.41602C19.5 4.81771 19.985 4.33268 20.5833 4.33268H23.8333Z'
              fill='#15B0E1'
            />
            <path
              d='M23.8385 21.666H17.3385C16.1419 21.666 15.1719 22.6361 15.1719 23.8327C15.1719 25.0293 16.1419 25.9993 17.3385 25.9993H23.8385C25.0352 25.9993 26.0052 25.0293 26.0052 23.8327C26.0052 22.6361 25.0352 21.666 23.8385 21.666Z'
              fill='#15B0E1'
            />
            <path
              d='M32.5052 30.334H17.3385C16.1419 30.334 15.1719 31.304 15.1719 32.5007C15.1719 33.6973 16.1419 34.6673 17.3385 34.6673H32.5052C33.7018 34.6673 34.6719 33.6973 34.6719 32.5007C34.6719 31.304 33.7018 30.334 32.5052 30.334Z'
              fill='#15B0E1'
            />
          </g>
        </g>
        <defs>
          <clipPath id='clip0_1135_3011'>
            <rect width='52' height='52' fill='white' />
          </clipPath>
          <clipPath id='clip1_1135_3011'>
            <rect width='52' height='52' fill='white' />
          </clipPath>
        </defs>
      </svg>
    ),
    title: 'Trusted Global Network',
    text: `Leverage our extensive network of sales professionals. Our diverse pool of candidates ensures you find the perfect fit for your business’s unique sales needs.`,
  },
];
const marketersExperts = [
  {
    logo: <img src={Exp2} />,
    name: 'Alicia M.',
    position: 'Sales Representative',
    time: 'Full-time',
    skills: ['Lead Generation: 5 years', 'Cold Calling: 6 years', 'Client Liaison: 6 years'],
    languages: [
      { name: 'English Native', icon: FlagEng },
      { name: 'Spanish Fluent', icon: FlagEsp },
    ],
  },

  {
    logo: <img src={Exp3} />,
    name: 'David L.',
    position: 'Account Executive',
    time: 'Full-time',
    skills: ['Client Relationship Management: 8 years', 'Contract Negotiation: 7 years', 'Sales Forecasting: 6 years'],
    languages: [
      { name: 'English Native', icon: FlagEng },
      { name: 'French Fluent', icon: FlagFr },
    ],
  },

  {
    logo: <img src={Exp1} />,
    name: 'Sophia R.',
    position: 'Account Manager',
    time: 'Part-time',
    skills: [
      'Client Retention Strategies: 5 years',
      'Customer Feedback Management: 4 years',
      'Renewal and Upsell Opportunities: 5 years',
    ],
    languages: [
      { name: 'Mandarin Native', icon: FlagMan },
      { name: 'English Fluent', icon: FlagEng },
    ],
  },

  {
    logo: <img src={Exp4} />,
    name: 'Ethan K.',
    position: 'Sales Analyst',
    time: 'Full-time',
    skills: ['Data Analysis: 6 years', 'Sales Performance Reporting: 6 years', 'Market Research: 7 years'],
    languages: [
      { name: 'English Native', icon: FlagEng },
      { name: 'German Conversational', icon: FlagGen },
    ],
  },
];

const MarketersPage = ({ onFormClick }) => {
  const [isMobile, setIsMobile] = useState(false);

  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth <= 1200);
  };

  useEffect(() => {
    setIsMobile(window.innerWidth <= 1200);
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <div className='innerPage'>
      <FirstScreen
        title={
          <>
            Boost Your Revenue with <span style={{ color: '#15B0E1' }}>Top&nbsp;Sales Professionals</span>
          </>
        }
        text='Pioneers connects you with top-tier sales professionals, pre-screened to meet your specific business needs. Drive revenue growth while we handle the hiring process.'
        color='#15B0E1'
        image={isMobile ? ImageMob : Image}
        onFormClick={onFormClick}
        buttonText='Get Started'
      />
      <div className='marketersServices'>
        <span className='mainPageSectionTitle'>Services Offered</span>
        <div className='marketersServicesRow'>
          <ServiceCard
            icon={
              <svg width='52' height='52' viewBox='0 0 52 52' fill='none'>
                <g clip-path='url(#clip0_1703_4053)'>
                  <path
                    opacity='0.5'
                    d='M25.9997 23.8333C21.2132 23.8333 17.333 19.9531 17.333 15.1667C17.333 10.3802 21.2132 6.5 25.9997 6.5C30.7861 6.5 34.6663 10.3802 34.6663 15.1667C34.6663 19.9531 30.7861 23.8333 25.9997 23.8333Z'
                    fill='#15B0E1'
                  />
                  <path
                    d='M6.50141 43.7649C7.34123 33.4241 15.7341 28.1666 25.9639 28.1666C36.3376 28.1666 44.8606 33.1352 45.4955 43.7666C45.5208 44.1902 45.4955 45.5 43.8679 45.5C35.8391 45.5 23.9086 45.5 8.07626 45.5C7.53288 45.5 6.45567 44.3282 6.50141 43.7649Z'
                    fill='#15B0E1'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_1703_4053'>
                    <rect width='52' height='52' fill='white' />
                  </clipPath>
                </defs>
              </svg>
            }
            title='Sales Development Representative'
            text='Drives new business opportunities by prospecting, qualifying leads, and closing sales. Focused on expanding your customer base and achieving sales targets.'
            tags={['Lead Generation', 'Cold Calling', 'Client Meetings', 'Product Presentations', 'Sales Negotiations']}
          />
          <ServiceCard
            icon={
              <svg width='53' height='52' viewBox='0 0 53 52'>
                <g clip-path='url(#clip0_1345_2769)'>
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M19.8335 21.6666V41.1666L22.3308 41.9991C24.9819 42.8828 27.7582 43.3333 30.5527 43.3333H38.3381C41.4365 43.3333 44.1042 41.1463 44.7119 38.1081L46.3351 29.9917C47.0392 26.4716 44.7563 23.0472 41.2361 22.3432C40.6745 22.2309 40.1005 22.1933 39.529 22.2314L31.7502 22.75L32.5393 14.07C32.6877 12.4375 31.4846 10.9939 29.8522 10.8455C29.7629 10.8374 29.6732 10.8333 29.5835 10.8333C27.5672 10.8333 25.6988 11.8912 24.6614 13.6202L19.8335 21.6666Z'
                    fill='#15B0E1'
                  />
                  <path
                    opacity='0.5'
                    d='M13.3337 19.5H6.83366C5.63704 19.5 4.66699 20.47 4.66699 21.6667V41.1667C4.66699 42.3633 5.63704 43.3333 6.83366 43.3333H13.3337C14.5303 43.3333 15.5003 42.3633 15.5003 41.1667V21.6667C15.5003 20.47 14.5303 19.5 13.3337 19.5Z'
                    fill='#15B0E1'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_1345_2769'>
                    <rect width='52' height='52' fill='white' transform='translate(0.333496)' />
                  </clipPath>
                </defs>
              </svg>
            }
            title='Account Executive'
            text='Builds and nurtures client relationships, focusing on upselling and cross-selling to maximize revenue from existing accounts. Responsible for managing key accounts and driving business growth.'
            tags={[
              'Client Relationship Management',
              'Contract Negotiation',
              'Account Strategy Development',
              'Sales Forecasting',
              'Performance Analysis',
            ]}
          />
        </div>
        <div className='marketersServicesRow'>
          <ServiceCard
            icon={
              <svg width='52' height='52' viewBox='0 0 52 52' fill='none'>
                <g clip-path='url(#clip0_1703_4058)'>
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M34.6666 33.8684V26C34.6666 22.4101 31.7565 19.5 28.1666 19.5H13.3506V11.9737C13.3506 8.95065 15.8012 6.5 18.8243 6.5H44.3681C47.3912 6.5 49.8418 8.95065 49.8418 11.9737V28.3947L49.878 38.538C49.8801 39.1364 49.3968 39.6231 48.7985 39.6252C48.5098 39.6263 48.2327 39.5121 48.0286 39.3079L42.5891 33.8684H34.6666Z'
                    fill='#15B0E1'
                  />
                  <path
                    opacity='0.5'
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M4.30078 39V28.1666C4.30078 25.7734 6.24088 23.8333 8.63411 23.8333H25.9674C28.3607 23.8333 30.3008 25.7734 30.3008 28.1666V39C30.3008 41.3932 28.3607 43.3333 25.9674 43.3333H8.88486L6.18985 45.9128C5.75762 46.3265 5.07186 46.3115 4.65815 45.8792C4.46517 45.6776 4.35744 45.4093 4.35744 45.1302V39.7025C4.32016 39.4739 4.30078 39.2392 4.30078 39ZM14.0832 30.3333C13.4849 30.3333 12.9998 30.8183 12.9998 31.4166C12.9998 32.015 13.4849 32.5 14.0832 32.5H24.9165C25.5148 32.5 25.9998 32.015 25.9998 31.4166C25.9998 30.8183 25.5148 30.3333 24.9165 30.3333H14.0832ZM20.5832 34.6666C19.9849 34.6666 19.4998 35.1517 19.4998 35.75C19.4998 36.3483 19.9849 36.8333 20.5832 36.8333H24.9165C25.5148 36.8333 25.9998 36.3483 25.9998 35.75C25.9998 35.1517 25.5148 34.6666 24.9165 34.6666H20.5832Z'
                    fill='#15B0E1'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_1703_4058'>
                    <rect width='52' height='52' fill='white' />
                  </clipPath>
                </defs>
              </svg>
            }
            title='Account Manager'
            text='Ensures client satisfaction by acting as the primary point of contact for your customers, managing ongoing relationships, and delivering tailored solutions to meet client needs.'
            tags={[
              'Client Retention Strategies',
              'Solution Implementation',
              'Customer Feedback Management',
              'Renewal and Upsell Opportunities',
              'Conflict Resolution',
            ]}
          />
          <ServiceCard
            icon={
              <svg width='52' height='52' viewBox='0 0 52 52' fill='none'>
                <g clip-path='url(#clip0_1703_4046)'>
                  <path
                    opacity='0.5'
                    d='M32.5 11.9167C32.5 10.1218 31.0449 8.66669 29.25 8.66669C27.4551 8.66669 26 10.1218 26 11.9167V33.5834C26 35.3783 27.4551 36.8334 29.25 36.8334C31.0449 36.8334 32.5 35.3783 32.5 33.5834V11.9167Z'
                    fill='#15B0E1'
                  />
                  <path
                    opacity='0.5'
                    d='M21.667 22.75C21.667 20.9551 20.2119 19.5 18.417 19.5C16.6221 19.5 15.167 20.9551 15.167 22.75V33.5833C15.167 35.3783 16.6221 36.8333 18.417 36.8333C20.2119 36.8333 21.667 35.3783 21.667 33.5833V22.75Z'
                    fill='#15B0E1'
                  />
                  <path
                    d='M10.8333 41.1667H43.3333C44.53 41.1667 45.5 42.1367 45.5 43.3333C45.5 44.53 44.53 45.5 43.3333 45.5H8.66667C7.47005 45.5 6.5 44.53 6.5 43.3333V8.66667C6.5 7.47005 7.47005 6.5 8.66667 6.5C9.86328 6.5 10.8333 7.47005 10.8333 8.66667V41.1667Z'
                    fill='#15B0E1'
                  />
                  <path
                    opacity='0.5'
                    d='M43.333 27.0833C43.333 25.2884 41.8779 23.8333 40.083 23.8333C38.2881 23.8333 36.833 25.2884 36.833 27.0833V33.5833C36.833 35.3782 38.2881 36.8333 40.083 36.8333C41.8779 36.8333 43.333 35.3782 43.333 33.5833V27.0833Z'
                    fill='#15B0E1'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_1703_4046'>
                    <rect width='52' height='52' fill='white' />
                  </clipPath>
                </defs>
              </svg>
            }
            title='Sales Analyst'
            text='Utilizes data to optimize sales strategies, providing insights into market trends, customer behavior, and sales performance. Essential for guiding your sales team to success.'
            tags={[
              'Data Analysis',
              'Sales Performance Reporting',
              'Market Research',
              'Sales Forecasting',
              'Strategy Recommendations',
            ]}
          />
        </div>
      </div>
      <div className='marketersHow'>
        <div className='marketersHowHolder'>
          <span className='mainPageSectionTitle'>How it works</span>
          <div className='marketersHowSteps'>
            <div className='marketersHowStep'>
              <span className='marketersHowStepCount' style={{ background: '#15B0E1' }}>
                1
              </span>
              <span className='marketersHowStepTitle'>Tell us about your project</span>
            </div>
            <span className='marketersHowArrow'>
              <svg width='56' height='38' viewBox='0 0 56 38'>
                <path d='M33.6714 1C34.1703 7.11679 39.1345 19.2798 54.9999 18.9975' stroke='#3F3F4D' stroke-width='6' />
                <path d='M33.6714 37C34.1703 30.8832 39.1345 18.7202 54.9999 19.0025' stroke='#3F3F4D' stroke-width='6' />
                <path d='M54.6304 18.9998H-0.000488281' stroke='#3F3F4D' stroke-width='6' />
              </svg>
            </span>
            <div className='marketersHowStep'>
              <span className='marketersHowStepCount' style={{ background: '#15B0E1' }}>
                2
              </span>
              <span className='marketersHowStepTitle'>Get matched with a sales professional in 48 hours</span>
            </div>
            <span className='marketersHowArrow'>
              <svg width='56' height='38' viewBox='0 0 56 38'>
                <path d='M33.6714 1C34.1703 7.11679 39.1345 19.2798 54.9999 18.9975' stroke='#3F3F4D' stroke-width='6' />
                <path d='M33.6714 37C34.1703 30.8832 39.1345 18.7202 54.9999 19.0025' stroke='#3F3F4D' stroke-width='6' />
                <path d='M54.6304 18.9998H-0.000488281' stroke='#3F3F4D' stroke-width='6' />
              </svg>
            </span>
            <div className='marketersHowStep'>
              <span className='marketersHowStepCount' style={{ background: '#15B0E1' }}>
                3
              </span>
              <span className='marketersHowStepTitle'>Start your two-week trial</span>
            </div>
          </div>
        </div>
      </div>

      <div className='trustedHolder trustedHolderGap marketersTrusted'>
        <Trusted />
      </div>
      <div className='reviewsHolder marketersReviews'>
        <div className='reviewCard'>
          <div className='reviewCardText'>
            “Pioneers really impressed us. They{' '}
            <span>
              connected us with a couple of Sales Reps who fit right in with our team and started making an impact immediately.
            </span>{' '}
            The process was straightforward, and the quality of candidates was top-notch. We’re definitely seeing the results.”
          </div>
          <div className='reviewCardData'>
            <div className='reviewCardPhoto'>
              <img src={Photo1} />
            </div>
            <div className='reviewCardNameData'>
              <span className='reviewCardName'>Mark T. Sales Director</span>
            </div>
          </div>
        </div>

        <div className='reviewCard'>
          <div className='reviewCardText'>
            “We had a great experience with Pioneers. They found us an Account Executive who’s been a perfect match for our needs.{' '}
            <span>From the initial consultation to the final hire, everything was handled professionally and efficiently.</span>{' '}
            We’ve already seen a noticeable uptick in our sales.”
          </div>
          <div className='reviewCardData'>
            <div className='reviewCardPhoto'>
              <img src={Photo3} />
            </div>
            <div className='reviewCardNameData'>
              <span className='reviewCardName'>Lisa R. VP of Sales</span>
            </div>
          </div>
        </div>

        <div className='reviewCard'>
          <div className='reviewCardText'>
            “I was a bit skeptical at first, but Pioneers came through for us. The Account Manager they placed with us has been
            excellent - <span>keeping our clients happy and even helping us secure some renewals.</span> It’s been a great
            partnership, and we couldn’t be more satisfied.”
          </div>
          <div className='reviewCardData'>
            <div className='reviewCardPhoto'>
              <img src={Photo2} />
            </div>
            <div className='reviewCardNameData'>
              <span className='reviewCardName'>Priya K. Client Relations Manager</span>
            </div>
          </div>
        </div>
      </div>

      <div className='marketersExpertsHolder'>
        <span className='mainPageSectionTitle'>Our Experts</span>

        <div className='marketersExpertsSliderHolder'>
          <div className='marketersExpertsSlider'>
            <div className='marketersExperts'>
              {marketersExperts.map(item => (
                <div className='marketersExpert'>
                  <div className='marketersExpertHeader'>
                    <span className='marketersExpertLogo'>{item.logo}</span>
                    <span className='marketersExpertName'>{item.name}</span>
                  </div>
                  <div className='marketersExpertDetails'>
                    <span className='marketersExpertDetail'>
                      <svg width='20' height='20' viewBox='0 0 20 20'>
                        <path
                          d='M12.4998 2.5C12.9601 2.5 13.3332 2.8731 13.3332 3.33333V5H17.4998C17.9601 5 18.3332 5.3731 18.3332 5.83333V16.6667C18.3332 17.1269 17.9601 17.5 17.4998 17.5H2.49984C2.0396 17.5 1.6665 17.1269 1.6665 16.6667V5.83333C1.6665 5.3731 2.0396 5 2.49984 5H6.6665V3.33333C6.6665 2.8731 7.0396 2.5 7.49984 2.5H12.4998ZM13.3332 6.66667H6.6665V15.8333H13.3332V6.66667ZM3.33317 6.66667V15.8333H4.99984V6.66667H3.33317ZM11.6665 4.16667H8.33317V5H11.6665V4.16667ZM14.9998 6.66667V15.8333H16.6665V6.66667H14.9998Z'
                          fill='white'
                        />
                      </svg>
                      {item.position}
                    </span>
                    <span className='marketersExpertDetail'>
                      <svg width='20' height='20' viewBox='0 0 20 20'>
                        <path
                          d='M9.99984 18.3334C5.39746 18.3334 1.6665 14.6024 1.6665 10.0001C1.6665 5.39771 5.39746 1.66675 9.99984 1.66675C14.6022 1.66675 18.3332 5.39771 18.3332 10.0001C18.3332 14.6024 14.6022 18.3334 9.99984 18.3334ZM9.99984 16.6667C13.6818 16.6667 16.6665 13.682 16.6665 10.0001C16.6665 6.31818 13.6818 3.33341 9.99984 3.33341C6.31794 3.33341 3.33317 6.31818 3.33317 10.0001C3.33317 13.682 6.31794 16.6667 9.99984 16.6667ZM10.8332 10.0001H14.1665V11.6667H9.1665V5.83341H10.8332V10.0001Z'
                          fill='white'
                        />
                      </svg>
                      {item.time}
                    </span>
                  </div>

                  <div className='marketersExpertSkills'>
                    {item.skills.map(skill => (
                      <span className='marketersExpertSkill'>
                        <svg width='20' height='20' viewBox='0 0 20 20'>
                          <path d='M8.5 12.1177L15 6L16 6.94118L8.5 14L4 9.76473L5 8.82356L8.5 12.1177Z' fill='white' />
                        </svg>
                        {skill}
                      </span>
                    ))}
                  </div>

                  <div className='marketersExpertLanguages'>
                    {item.languages.map(language => (
                      <span className='marketersExpertLanguage'>
                        <img src={language.icon} />
                        {language.name}
                      </span>
                    ))}
                  </div>
                  <div className='marketersExpertButton' style={{ background: '#15B0E1' }} onClick={onFormClick}>
                    Hire Now
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className='marketersWhyHolder'>
        <span className='mainPageSectionTitle'>Why Choose Pioneers?</span>
        <div className='marketersWhyStats'>
          <div className='marketersWhyStat'>
            <span className='marketersWhyStatCount' style={{ color: '#15B0E1' }}>
              35%
            </span>
            <span className='marketersWhyStatText'>Lower Recruitment Costs</span>
          </div>
          <div className='marketersWhyStat'>
            <span className='marketersWhyStatCount' style={{ color: '#15B0E1' }}>
              48 hrs
            </span>
            <span className='marketersWhyStatText'>to Match with Top Sales Talent</span>
          </div>
          <div className='marketersWhyStat'>
            <span className='marketersWhyStatCount' style={{ color: '#15B0E1' }}>
              25%
            </span>
            <span className='marketersWhyStatText'>Higher retention rate</span>
          </div>
          <div className='marketersWhyStat'>
            <span className='marketersWhyStatCount' style={{ color: '#15B0E1' }}>
              1K+
            </span>
            <span className='marketersWhyStatText'>Sales Professionals Available Now</span>
          </div>
        </div>

        <MainPoints points={points} />
      </div>
    </div>
  );
};

export default MarketersPage;
