import { useState, useEffect } from 'react';
import './Engineering.scss';
import cn from 'classnames';
import FirstScreen from '../../components/FirstScreen';
import MainPoints from '../../components/MainPoints';
import Trusted from '../../components/Trusted';
import StepsCards from '../../components/StepsCards';
import Image from '../../assets/inner1.png';
import ImageMob from '../../assets/inner11.png';
import Step1 from '../../assets/steps/21.png';
import Step2 from '../../assets/steps/22.png';
import Step3 from '../../assets/steps/23.png';
import Reviews from '../../components/Reviews';

const points = [
  {
    icon: (
      <svg width='52' height='52' viewBox='0 0 52 52' fill='none'>
        <g clip-path='url(#clip0_1135_2523)'>
          <path
            opacity='0.5'
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M8.39753 8.11546L48.1615 18.7702C49.3173 19.0799 50.0032 20.268 49.6935 21.4238L44.0858 42.3522C43.7761 43.508 42.588 44.194 41.4322 43.8843L1.66823 33.2295C0.512388 32.9198 -0.173541 31.7318 0.136167 30.5759L5.74391 9.64753C6.05362 8.49168 7.24168 7.80575 8.39753 8.11546ZM22.6718 34.3712C27.2951 35.61 32.0474 32.8663 33.2862 28.243C34.525 23.6196 31.7813 18.8673 27.158 17.6285C22.5346 16.3897 17.7823 19.1334 16.5435 23.7568C15.3047 28.3801 18.0484 33.1324 22.6718 34.3712Z'
            fill='#4E75FF'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M4.33073 13H45.4974C46.694 13 47.6641 13.97 47.6641 15.1667V36.8333C47.6641 38.03 46.694 39 45.4974 39H4.33073C3.13411 39 2.16406 38.03 2.16406 36.8333V15.1667C2.16406 13.97 3.13411 13 4.33073 13ZM24.9141 34.6667C29.7005 34.6667 33.5807 30.7865 33.5807 26C33.5807 21.2135 29.7005 17.3333 24.9141 17.3333C20.1276 17.3333 16.2474 21.2135 16.2474 26C16.2474 30.7865 20.1276 34.6667 24.9141 34.6667ZM24.9141 30.3333C27.3073 30.3333 29.2474 28.3932 29.2474 26C29.2474 23.6068 27.3073 21.6667 24.9141 21.6667C22.5208 21.6667 20.5807 23.6068 20.5807 26C20.5807 28.3932 22.5208 30.3333 24.9141 30.3333Z'
            fill='#4E75FF'
          />
        </g>
        <defs>
          <clipPath id='clip0_1135_2523'>
            <rect width='52' height='52' fill='white' />
          </clipPath>
        </defs>
      </svg>
    ),
    title: 'Cost-Effective',
    text: 'We automate the search process to access a vast pool of exceptional talent while keeping costs low',
  },
  {
    icon: (
      <svg width='52' height='52' viewBox='0 0 52 52' fill='none'>
        <g clip-path='url(#clip0_1135_2528)'>
          <path
            opacity='0.5'
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M24.3994 12.5969L4.33594 34.6667H47.6693L27.6058 12.5969C26.8009 11.7115 25.4306 11.6462 24.5451 12.4512C24.4943 12.4974 24.4456 12.5461 24.3994 12.5969Z'
            fill='#4E75FF'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M26.0149 34.0419L43.9439 14.8478C44.7607 13.9734 46.1317 13.9266 47.0062 14.7435C47.4449 15.1532 47.6939 15.7266 47.6939 16.3268V43.3332H34.6939H4.33594V16.3268C4.33594 15.7266 4.58495 15.1532 5.02361 14.7435C5.89807 13.9266 7.26913 13.9734 8.08596 14.8478L26.0149 34.0419Z'
            fill='#4E75FF'
          />
        </g>
        <defs>
          <clipPath id='clip0_1135_2528'>
            <rect width='52' height='52' fill='white' />
          </clipPath>
        </defs>
      </svg>
    ),
    title: 'Diverse Seniority Levels',
    text: `Find candidates ranging from promising interns at top schools to seasoned professionals`,
  },
  {
    icon: (
      <svg width='52' height='52' viewBox='0 0 52 52' fill='none'>
        <g clip-path='url(#clip0_1134_2089)'>
          <g clip-path='url(#clip1_1134_2089)'>
            <path
              opacity='0.5'
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M17.3385 6.5V7.58333C17.3385 9.37826 18.7936 10.8333 20.5885 10.8333H31.4219C33.2168 10.8333 34.6719 9.37826 34.6719 7.58333V6.5H39.0052C41.3984 6.5 43.3385 8.4401 43.3385 10.8333V45.5C43.3385 47.8932 41.3984 49.8333 39.0052 49.8333H13.0052C10.612 49.8333 8.67188 47.8932 8.67188 45.5V10.8333C8.67188 8.4401 10.612 6.5 13.0052 6.5H17.3385Z'
              fill='#4E75FF'
            />
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M23.8333 4.33268C23.8333 3.13607 24.8034 2.16602 26 2.16602C27.1966 2.16602 28.1667 3.13607 28.1667 4.33268H31.4167C32.015 4.33268 32.5 4.81771 32.5 5.41602V7.58268C32.5 8.18099 32.015 8.66602 31.4167 8.66602H20.5833C19.985 8.66602 19.5 8.18099 19.5 7.58268V5.41602C19.5 4.81771 19.985 4.33268 20.5833 4.33268H23.8333Z'
              fill='#4E75FF'
            />
            <path
              d='M23.8385 21.666H17.3385C16.1419 21.666 15.1719 22.6361 15.1719 23.8327C15.1719 25.0293 16.1419 25.9993 17.3385 25.9993H23.8385C25.0352 25.9993 26.0052 25.0293 26.0052 23.8327C26.0052 22.6361 25.0352 21.666 23.8385 21.666Z'
              fill='#4E75FF'
            />
            <path
              d='M32.5052 30.334H17.3385C16.1419 30.334 15.1719 31.304 15.1719 32.5007C15.1719 33.6973 16.1419 34.6673 17.3385 34.6673H32.5052C33.7018 34.6673 34.6719 33.6973 34.6719 32.5007C34.6719 31.304 33.7018 30.334 32.5052 30.334Z'
              fill='#4E75FF'
            />
          </g>
        </g>
        <defs>
          <clipPath id='clip0_1134_2089'>
            <rect width='52' height='52' fill='white' />
          </clipPath>
          <clipPath id='clip1_1134_2089'>
            <rect width='52' height='52' fill='white' />
          </clipPath>
        </defs>
      </svg>
    ),
    title: 'Paperwork',
    text: `Don't want to hire directly? No problem. We can operate as a staffing agency, taking care of all the paperwork and regulations, so you can focus on growing your business.`,
  },
  {
    icon: (
      <svg width='52' height='52' viewBox='0 0 52 52' fill='none'>
        <g clip-path='url(#clip0_1135_2533)'>
          <path
            opacity='0.5'
            d='M39.0026 30.3333C35.4128 30.3333 32.5026 27.4232 32.5026 23.8333C32.5026 20.2435 35.4128 17.3333 39.0026 17.3333C42.5925 17.3333 45.5026 20.2435 45.5026 23.8333C45.5026 27.4232 42.5925 30.3333 39.0026 30.3333ZM19.5026 23.8333C14.7161 23.8333 10.8359 19.9531 10.8359 15.1667C10.8359 10.3802 14.7161 6.5 19.5026 6.5C24.2891 6.5 28.1693 10.3802 28.1693 15.1667C28.1693 19.9531 24.2891 23.8333 19.5026 23.8333Z'
            fill='#4E75FF'
          />
          <path
            d='M38.1359 32.5017C45.5167 32.5823 51.5432 36.314 51.9967 44.2003C52.015 44.518 51.9967 45.5003 50.8212 45.5003H42.4667C42.4667 40.6233 40.8553 36.1226 38.1359 32.5017ZM0.00141198 43.7653C0.841227 33.4244 9.23414 28.167 19.4639 28.167C29.8376 28.167 38.3606 33.1356 38.9955 43.767C39.0208 44.1905 38.9955 45.5003 37.3679 45.5003C29.3391 45.5003 17.4086 45.5003 1.57625 45.5003C1.03288 45.5003 -0.0443338 44.3285 0.00141198 43.7653Z'
            fill='#4E75FF'
          />
        </g>
        <defs>
          <clipPath id='clip0_1135_2533'>
            <rect width='52' height='52' fill='white' />
          </clipPath>
        </defs>
      </svg>
    ),
    title: 'Global Talent Pool',
    text: `Tap into a vast network of pre-screened, highly skilled developers worldwide`,
  },
];

const steps = [
  {
    image: Step1,
    title: 'Unique Matches',
    text: `At Pioneers, we connect you with pre-vetted, highly skilled software engineers who perfectly match your requirements. 
        Don't have a technical job description? No problem. Simply tell us the expertise you need, and we'll find the ideal candidates for you.`,
  },
  {
    image: Step2,
    title: 'Relevant candidates in just two weeks',
    text: `We don't sell software; we ensure you get the right matches. Within two weeks, we'll provide you with skilled software engineers who are genuinely interested and motivated to join your development team and contribute to your project's success.`,
  },
  {
    image: Step3,
    title: 'No Commitment, No Risk, Affordable Cost',
    text: `With Pioneers, you only pay if you hire a developer. There's no long-term commitment or upfront fees, giving you the flexibility to scale your development team as needed.`,
  },
];

const EngineeringPage = ({ onFormClick }) => {
  const [isMobile, setIsMobile] = useState(false);

  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth <= 1200);
  };

  useEffect(() => {
    setIsMobile(window.innerWidth <= 1200);
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <div className='innerPage'>
      <FirstScreen
        title={
          <>
            <span style={{ color: '#4E75FF' }}>Scale</span> Your <span style={{ color: '#4E75FF' }}>Development Team</span> with
            Top-Tier Talent
          </>
        }
        text='Discover skilled, motivated software engineers ready to make an impact. Connect with top tech talent to scale your development team through full-time, part-time, remote, hybrid, or in-person roles, tailored to your specific needs.'
        color='#4E75FF'
        image={isMobile ? ImageMob : Image}
        onFormClick={onFormClick}
      />

      <MainPoints points={points} />
      <StepsCards cards={steps} color='#4E75FF' />
      <div className='trustedHolder trustedHolderGap'>
        <Trusted />
      </div>
      <Reviews />
    </div>
  );
};

export default EngineeringPage;
