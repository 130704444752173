import { useState } from 'react';
import './404.scss';
import Form from '../../components/Form';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useNavigate } from 'react-router-dom';

const BlogPage = () => {
  const [isFormOpen, setFormOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <div className='page'>
      <div className='contentHolder'>
        <Header onFormClick={() => setFormOpen(true)} />
        <div className='notFoundPage'>
          <span className='notFoundPageIcon'>
            <svg width='260' height='95' viewBox='0 0 260 95' fill='none'>
              <path
                d='M260 72.4361H243.763V93.1944H227.264V72.4361H176.981V60.6861L222.157 1.80555H240.358L197.801 58.075H227.787V39.6667H243.763V58.075H260V72.4361Z'
                fill='#242424'
              />
              <path
                d='M128.418 94.5C121.085 94.5 114.537 92.6722 108.776 89.0167C103.014 85.2741 98.4748 79.8778 95.1575 72.8278C91.8403 65.6907 90.1816 57.2482 90.1816 47.5C90.1816 37.7519 91.8403 29.3528 95.1575 22.3028C98.4748 15.1657 103.014 9.76945 108.776 6.11389C114.537 2.3713 121.085 0.5 128.418 0.5C135.751 0.5 142.298 2.3713 148.059 6.11389C153.908 9.76945 158.491 15.1657 161.809 22.3028C165.126 29.3528 166.785 37.7519 166.785 47.5C166.785 57.2482 165.126 65.6907 161.809 72.8278C158.491 79.8778 153.908 85.2741 148.059 89.0167C142.298 92.6722 135.751 94.5 128.418 94.5ZM128.418 79.7472C134.965 79.7472 140.115 77.0491 143.869 71.6528C147.71 66.2565 149.631 58.2056 149.631 47.5C149.631 36.7944 147.71 28.7435 143.869 23.3472C140.115 17.9509 134.965 15.2528 128.418 15.2528C121.958 15.2528 116.807 17.9509 112.966 23.3472C109.212 28.7435 107.335 36.7944 107.335 47.5C107.335 58.2056 109.212 66.2565 112.966 71.6528C116.807 77.0491 121.958 79.7472 128.418 79.7472Z'
                fill='#242424'
              />
              <path
                d='M83.0193 72.4361H66.7821V93.1944H50.283V72.4361H0V60.6861L45.1761 1.80555H63.3775L20.8203 58.075H50.8068V39.6667H66.7821V58.075H83.0193V72.4361Z'
                fill='#242424'
              />
              <path
                d='M139.414 60.1268C136.71 57.1607 132.81 55.2989 128.474 55.2989C124.138 55.2989 120.239 57.1607 117.534 60.1268L120.328 62.6613C122.664 61.7364 125.464 61.1981 128.474 61.1981C131.484 61.1981 134.285 61.7364 136.62 62.6613L139.414 60.1268Z'
                fill='#242424'
              />
              <path
                d='M122.561 44.9755C122.561 42.532 120.575 40.5511 118.125 40.5511C115.676 40.5511 113.69 42.532 113.69 44.9755C113.69 47.4189 115.676 49.3998 118.125 49.3998C120.575 49.3998 122.561 47.4189 122.561 44.9755Z'
                fill='#242424'
              />
              <path
                d='M143.258 44.9755C143.258 42.532 141.273 40.5511 138.823 40.5511C136.374 40.5511 134.388 42.532 134.388 44.9755C134.388 47.4189 136.374 49.3998 138.823 49.3998C141.273 49.3998 143.258 47.4189 143.258 44.9755Z'
                fill='#242424'
              />
            </svg>
          </span>
          <span className='notFoundPageText'>
            The page you are looking for doesn't exist or has been moved. Please go back to the homepage.
          </span>
          <span className='notFoundPageButton' onClick={() => navigate('/')}>
            Go Back Home{' '}
            <svg className='notFoundPageButtonIcon' viewBox='0 0 36 26' fill='none'>
              <path d='M22.1326 1.06787C22.4336 5.12205 25.4284 13.1836 34.9998 12.9965' stroke='black' stroke-width='3.3' />
              <path d='M22.1326 24.9316C22.4336 20.8775 25.4284 12.8159 34.9998 13.003' stroke='black' stroke-width='3.3' />
              <path d='M35 12.9995L0.000244141 12.9995' stroke='black' stroke-width='3.3' />
            </svg>
          </span>
        </div>
        <Footer />
      </div>
      {isFormOpen && <Form onClose={() => setFormOpen(false)} />}
    </div>
  );
};

export default BlogPage;
