import './ServiceCard.scss';

const ServiceCard = ({ icon, title, text, tags }) => {
  return (
    <div className='serviceCard'>
      <span className='serviceCardIcon'>{icon}</span>
      <span className='serviceCardTitle'>{title}</span>
      <span className='serviceCardText'>{text}</span>
      <div className='serviceCardTags'>
        {tags.map(tag => (
          <span className='serviceCardTag'>{tag}</span>
        ))}
      </div>
    </div>
  );
};

export default ServiceCard;
