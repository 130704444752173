import './Trusted.scss';
import Epam from '../../assets/logos/epam.svg';
import Grid from '../../assets/logos/grid.svg';
import Beyond from '../../assets/logos/beyond.svg';
import Eyeque from '../../assets/logos/eyeque.svg';
import PNM from '../../assets/logos/PNM.svg';
import Thrive from '../../assets/logos/thrive.svg';
import Thryv from '../../assets/logos/Thryv.svg';
import Artist from '../../assets/logos/artistongo.svg';
import Splainers from '../../assets/logos/splainers.svg';
import Heimdall from '../../assets/logos/heimdall.svg';
import Inspir from '../../assets/logos/inspir.png';
import Sinai from '../../assets/logos/sinai.png';
import Mayo from '../../assets/logos/mayo.png';
import Hss from '../../assets/logos/hss.png';
import Applewood from '../../assets/logos/applewood.png';

const Trusted = ({ type = 'default' }) => {
  return (
    <div className='pioneersTrust universitiesOverlay'>
      <span className='mainPageSectionTitle'>Trusted by</span>
      <div className='universitiesHolder'>
        {type === 'default' ? (
          <>
            <div className='universitiesHolderRow'>
              <div className='universityBox'>
                <span className='university'>
                  <img src={Thryv} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Epam} alt='logo' />
                </span>
                <span className='university'>
                  <img src={PNM} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Eyeque} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Thrive} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Beyond} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Grid} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Splainers} style={{ marginTop: '-20px' }} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Heimdall} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Artist} alt='logo' />
                </span>
              </div>
              <div className='universityBox'>
                <span className='university'>
                  <img src={Thryv} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Epam} alt='logo' />
                </span>
                <span className='university'>
                  <img src={PNM} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Eyeque} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Thrive} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Beyond} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Grid} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Splainers} style={{ marginTop: '-20px' }} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Heimdall} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Artist} alt='logo' />
                </span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='universitiesHolderRow'>
              <div className='universityBox'>
                <span className='university'>
                  <img src={Applewood} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Hss} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Inspir} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Mayo} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Sinai} alt='logo' />
                </span>
              </div>
              <div className='universityBox'>
                <span className='university'>
                  <img src={Applewood} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Hss} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Inspir} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Mayo} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Sinai} alt='logo' />
                </span>
              </div>
              <div className='universityBox'>
                <span className='university'>
                  <img src={Applewood} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Hss} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Inspir} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Mayo} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Sinai} alt='logo' />
                </span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Trusted;
