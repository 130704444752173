import { useState } from 'react';
import './Layout.scss';
import Header from '../Header';
import Footer from '../Footer';
import FullButton from '../FullButton';
import Form from '../Form';
import { useLottie } from 'lottie-react';
import animation from '../../assets/loader.json';
import { useEffect } from 'react';
import EducationPage from '../../pages/EducationPage';
import EngineeringPage from '../../pages/EngineeringPage';
import MarketersPage from '../../pages/MarketersPage';
import AssistantsPage from '../../pages/AssistantsPage';
import MainPage from '../../pages/MainPage';
import NursesPage from '../../pages/NursesPages';
import NursesWorkPage from '../../pages/NursesPages/candidateView';
import SalesPage from '../../pages/SalesPage';

const options = {
  animationData: animation,
  loop: true,
};

const Layout = props => {
  const [isFormOpen, setFormOpen] = useState(false);
  const { View } = useLottie(options);
  const [isEnd, setIsEnd] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsEnd(true), 2500);
  }, []);

  return (
    <div className='page'>
      <div className='contentHolder'>
        <Header onFormClick={() => setFormOpen(true)} />
        <div className='content'>
          {props.type === 'main' && <MainPage onFormClick={() => setFormOpen(true)} />}
          {props.type === 'edu' && <EducationPage onFormClick={() => setFormOpen(true)} />}
          {props.type === 'eng' && <EngineeringPage onFormClick={() => setFormOpen(true)} />}
          {props.type === 'mar' && <MarketersPage onFormClick={() => setFormOpen(true)} />}
          {props.type === 'ass' && <AssistantsPage onFormClick={() => setFormOpen(true)} />}
          {props.type === 'nurHire' && <NursesPage onFormClick={() => setFormOpen(true)} />}
          {props.type === 'nurWork' && <NursesWorkPage onFormClick={() => setFormOpen(true)} />}
          {props.type === 'sal' && <SalesPage onFormClick={() => setFormOpen(true)} />}
        </div>
        <FullButton
          buttonText={props.type === 'ass' || props.type === 'mar' || props.type === 'nur' ? 'Get Started Now' : 'Contact Us'}
          color={props.color}
          onFormClick={() => setFormOpen(true)}
        />
        <Footer />
      </div>
      {!isEnd && (
        <div className='loaderHolder'>
          <div className='loader'>{View}</div>
        </div>
      )}
      {isFormOpen && <Form onClose={() => setFormOpen(false)} />}
    </div>
  );
};

export default Layout;
