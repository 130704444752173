import './Reviews.scss';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import Logo1 from '../../assets/reviews/logo1.svg';
import Logo2 from '../../assets/reviews/logo2.svg';
import Logo3 from '../../assets/reviews/logo3.svg';
import Photo1 from '../../assets/reviews/rev1.jpg';
import Photo2 from '../../assets/reviews/rev2.jpg';
import Photo3 from '../../assets/reviews/rev3.jpg';

const Reviews = props => {
  return (
    <div className='reviewsHolder'>
      <div className='reviewCard'>
        {!props.withoutLogo && (
          <div className='reviewCardLogo'>
            <svg viewBox='0 0 164 88' fill='none'>
              <g clip-path='url(#clip0_1135_2904)'>
                <path
                  d='M32.8083 41.0849H41.3026V32.0908C41.3026 29.5838 40.0499 28.3303 37.5445 28.3303H36.605C34.0996 28.3303 32.8488 29.5838 32.8525 32.0908L32.8083 41.0849ZM32.8083 46.8226V56.7139C32.8083 59.2246 34.0591 60.4781 36.5608 60.4744H37.6551C40.1568 60.4744 41.4095 59.2209 41.4132 56.7139V51.8513H47.1995V56.4758C47.1995 62.8226 43.9941 66.0404 37.6496 66.0404H36.4171C30.0781 66.0404 26.8672 62.8337 26.8672 57.2234V33.0711C26.8672 25.971 30.0726 22.7588 36.4171 22.7588H37.6661C44.0051 22.7588 47.2161 25.9765 47.2161 32.3289V46.8226H32.8083Z'
                  fill='white'
                />
                <path
                  d='M57.0091 58.1982C57.3792 58.54 57.7807 58.846 58.2084 59.112C58.6362 59.3813 59.0876 59.6112 59.5569 59.7987C60.0253 59.9869 60.51 60.1315 61.0048 60.2307C61.4982 60.3304 61.9994 60.386 62.5025 60.3969C64.6966 60.3969 65.9456 59.3723 65.9456 56.9465V31.8637C65.9456 29.4158 64.6966 28.4134 62.5025 28.4134C61.9994 28.4243 61.4982 28.4799 61.0048 28.5795C60.51 28.6787 60.0253 28.8233 59.5569 29.0115C59.0876 29.1991 58.6362 29.4289 58.2084 29.6982C57.7807 29.9643 57.3792 30.2702 57.0091 30.6121V58.1982ZM51.0625 23.2295H57.0091V25.9765C57.4714 25.4743 57.9845 25.0214 58.54 24.6252C59.094 24.2256 59.6887 23.8859 60.314 23.6117C60.9373 23.3351 61.5873 23.1236 62.2538 22.9803C62.9225 22.8374 63.6039 22.7631 64.2876 22.7588C69.3721 22.7588 71.8756 26.1316 71.8756 31.1493V57.6499C71.8756 62.6676 69.3721 66.0348 64.2876 66.0348C62.9328 66.0369 61.5912 65.7676 60.3416 65.2429C59.7192 64.9791 59.1233 64.6565 58.5621 64.2792C58.0017 63.8993 57.4814 63.4632 57.0091 62.9777V76.6406H51.0625V23.2295Z'
                  fill='white'
                />
                <path
                  d='M89.7186 58.4314V44.475L84.3246 46.5242C82.1306 47.3826 81.4287 48.4792 81.4287 50.916V57.357C81.4287 59.3951 82.4511 60.4141 84.3246 60.4141C86.1981 60.4141 87.7622 59.7662 89.7186 58.4314ZM75.4766 50.8274C75.4766 45.5716 77.2009 43.4892 81.7382 41.8942L89.713 39.1472V32.0914C89.713 29.5826 88.464 28.2479 85.9605 28.2479H84.9878C82.4898 28.2479 81.2353 29.5826 81.2353 32.0914V38.0838H75.4766V32.324C75.4766 25.9716 78.682 22.7539 85.0265 22.7539H86.1484C92.4874 22.7539 95.6983 25.9716 95.6983 32.324V65.5647H89.7517V62.9008C89.2824 63.3934 88.7659 63.8386 88.2098 64.23C86.5278 65.4075 84.525 66.0378 82.4732 66.0355C77.7038 66.0355 75.5097 63.2885 75.5097 58.5865L75.4766 50.8274Z'
                  fill='white'
                />
                <path
                  d='M119.717 65.5696H113.776V31.465C113.776 29.3493 112.753 28.4078 110.957 28.4078C109.316 28.4078 107.597 29.017 105.464 30.5234V65.5696H99.5391V23.2295H105.48V25.9765C107.204 24.3317 109.476 22.7588 112.759 22.7588C115.815 22.7588 117.843 24.016 118.937 26.2091C121.049 24.4092 123.552 22.7588 126.995 22.7588C131.61 22.7588 133.959 25.5833 133.959 30.2133V65.5696H127.984V31.465C127.984 29.3493 126.967 28.4078 125.171 28.4078C123.53 28.4078 121.806 29.017 119.678 30.5234L119.717 65.5696Z'
                  fill='white'
                />
                <path
                  d='M19.2566 27.1289V34.9157L9.24797 44.4692L19.2566 53.8566V61.8096L3.99219 47.4211V41.6946L19.2566 27.1289Z'
                  fill='white'
                />
                <path
                  d='M156.772 41.6946V47.4211L141.508 61.8096V53.8566L151.516 44.4692L141.508 34.9157V27.1289L156.772 41.6946Z'
                  fill='white'
                />
              </g>
              <defs>
                <clipPath id='clip0_1135_2904'>
                  <rect width='152.782' height='53.8818' fill='white' transform='translate(3.99219 22.7539)' />
                </clipPath>
              </defs>
            </svg>
          </div>
        )}
        <div className='reviewCardText'>
          "Working with you guys has been fantastic. Not only is <span>the quality of the candidates</span> you've sourced for our
          technical roles outstanding, but the <span>speed of vetting them</span> is also impressive. You've rapidly provided us
          with exactly the kind of talent we were looking for. Thanks for making the{' '}
          <span>recruitment process efficient and spot-on!</span>"
        </div>
        <div className='reviewCardData'>
          <div className='reviewCardPhoto'>
            <img src={Photo1} />
          </div>
          <div className='reviewCardNameData'>
            <span className='reviewCardName'>Theo Piletski,</span>
            <span className='reviewCardRole'>VP Strategy & Business Consulting</span>
          </div>
        </div>
      </div>

      <div className='reviewCard'>
        {!props.withoutLogo && (
          <div className='reviewCardLogo'>
            <img src={Logo2} />
          </div>
        )}
        <div className='reviewCardText'>
          "We are having <span>great success</span> with Jake and Halime already so I am guessing{' '}
          <span>our intern program will grow</span> over the next few months. We've been pretty darn impressed with{' '}
          <span>the quality of the candidates.</span>"
        </div>
        <div className='reviewCardData'>
          <div className='reviewCardPhoto'>
            <img src={Photo2} />
          </div>
          <div className='reviewCardNameData'>
            <span className='reviewCardName'>Andrew Darress,</span>
            <span className='reviewCardRole'>Director, Education</span>
          </div>
        </div>
      </div>

      <div className='reviewCard'>
        {!props.withoutLogo && (
          <div className='reviewCardLogo'>
            <img src={Logo3} />
          </div>
        )}
        <div className='reviewCardText'>
          "It's been a real pleasure working with Raissa, and <span>the quality of the candidate pool is remarkable.</span> Could
          you help us in our search for full-time Product Managers and Software Engineers? And, would it also be possible to find
          good candidates in about two weeks?"
        </div>
        <div className='reviewCardData'>
          <div className='reviewCardPhoto'>
            <img src={Photo3} />
          </div>
          <div className='reviewCardNameData'>
            <span className='reviewCardName'>Gordon Henry,</span>
            <span className='reviewCardRole'>Chief Strategy Officer</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
