import { useState } from 'react';
import './Layout.scss';
import HeaderOld from '../HeaderOld';
import Form from '../Form';
import Investors from '../../pages/Investors';
import { useLottie } from 'lottie-react';
import animation from '../../assets/loader.json';
import { useEffect } from 'react';

const options = {
  animationData: animation,
  loop: true,
};

const Layout = () => {
  const [isFormOpen, setFormOpen] = useState(false);
  const { View } = useLottie(options);
  const [isEnd, setIsEnd] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsEnd(true), 2500);
  }, []);

  return (
    <div className='page'>
      {isEnd ? (
        <div className='contentHolder'>
          <HeaderOld onFormClick={() => setFormOpen(true)} />
          <div className='content'>
            <Investors onFormClick={() => setFormOpen(true)} />
          </div>
        </div>
      ) : (
        <div className='loader'>{View}</div>
      )}
      {isFormOpen && <Form onClose={() => setFormOpen(false)} />}
    </div>
  );
};

export default Layout;
