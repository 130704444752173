import { useEffect, useState } from 'react';
import './Form.scss';
import { load } from 'recaptcha-v3';
import cn from 'classnames';
import useGeoLocation from 'react-ipgeolocation';

const Form = ({ onClose }) => {
  const [isSuccess, setSuccess] = useState(false);
  const [selectedType, setSelectedType] = useState('client');
  const [isSending, setSending] = useState(false);
  const location = useGeoLocation();

  useEffect(() => {
    window.addEventListener('keydown', handlePress);
    document.body.classList.add('no-scroll');

    return () => {
      window.removeEventListener('keydown', handlePress);
    };
  }, []);

  const handleClose = () => {
    document.body.classList.remove('no-scroll');
    onClose();
    setSuccess(false);
  };

  const handlePress = e => {
    if (e.key === 'Escape') {
      handleClose();
    }
  };

  const validateEmail = email => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  };

  const handleSend = e => {
    setSending(true);
    e.preventDefault();
    const name = document.querySelector('#name').value;
    const phone = document.querySelector('#phone').value;
    const email = document.querySelector('#email').value;
    const company = document.querySelector('#company').value || '';
    const message_text = document.querySelector('#message_text').value || '';

    if (!!name && validateEmail(email)) {
      load('6LcKQrYpAAAAAANwmpPi8upTaH7r_fTZ19S8a0wM').then(recaptcha => {
        recaptcha
          .execute('submit')
          .then(token => {
            fetch('https://j5opjv2ww1.execute-api.us-east-1.amazonaws.com/prod/contact-us', {
              method: 'POST',
              body: JSON.stringify({
                token,
                name,
                company,
                email,
                phone,
                message_text,
                sender_type: selectedType,
                country: location?.country || '',
              }),
              headers: {
                'Content-Type': 'application/json',
              },
            });
          })
          .then(() => {
            setSuccess(true);
            setTimeout(handleClose, 3500);
            setSending(false);
          });
      });
    }
  };

  return (
    <div className={cn('popup', isSuccess && 'popup-success')}>
      {isSuccess ? (
        <div className='success'>
          <svg width='25' height='24' viewBox='0 0 25 24' fill='none'>
            <path d='M20.5 6L9.5 17L4.5 12' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
          </svg>
          <span className='success-text'>Thank you. We'll get back to you soon.</span>
        </div>
      ) : (
        <div className='form'>
          <span className='form-close' onClick={handleClose}>
            <svg width='28' height='28' viewBox='0 0 28 28' fill='none'>
              <path d='M23.9006 23.8996L4.10156 4.10059' stroke='#4F4CE0' stroke-width='2' />
              <path d='M4.09945 23.8996L23.8984 4.10059' stroke='#4F4CE0' stroke-width='2' />
            </svg>
          </span>
          <span className='form-title'>Contact Sales as...</span>
          <form className='form-content'>
            <div className='form-type-holder'>
              <span
                className={cn('form-type', selectedType === 'client' && 'form-type-active')}
                onClick={() => setSelectedType('client')}
              >
                Client
              </span>
              <span
                className={cn('form-type', selectedType === 'candidate' && 'form-type-active')}
                onClick={() => setSelectedType('candidate')}
              >
                Candidate
              </span>
              <span
                className={cn('form-type', selectedType === 'partner' && 'form-type-active')}
                onClick={() => setSelectedType('partner')}
              >
                Partner
              </span>
            </div>
            <label className='form-label'>
              <input minLength='1' required placeholder='' className='form-input' name='name' id='name' />
              <span>
                Name <sup>*</sup>
              </span>
              <span className='form-error'>Name is required</span>
            </label>

            <label className='form-label'>
              <input placeholder=' ' className='form-input' name='company' id='company' />
              <span>Company</span>
            </label>

            <label className='form-label'>
              <input
                required
                placeholder=' '
                type='email'
                minLength='1'
                className='form-input'
                name='email'
                id='email'
                pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'
              />
              <span>
                Email <sup>*</sup>
              </span>
              <span className='form-error'>Wrong email format</span>
            </label>

            <label className='form-label'>
              <input placeholder=' ' className='form-input' name='phone' id='phone' />
              <span>Phone number</span>
            </label>

            <label className='form-label'>
              <textarea placeholder=' ' className='form-input form-textarea' name='message_text' id='message_text' />
              <span>
                Please describe your talent needs. Include the roles you're looking to fill, key skills required, project details,
                work arrangement preferences (remote/in-person), and any specific industry requirements.
              </span>
            </label>

            <button type='submit' aria-disabled='true' disabled={isSending} className='form-button' onClick={handleSend}>
              Submit
              <svg className='submit-icon' viewBox='0 0 36 26' fill='none'>
                <path d='M22.1326 1.06787C22.4336 5.12205 25.4284 13.1836 34.9998 12.9965' stroke='black' stroke-width='3.3' />
                <path d='M22.1326 24.9316C22.4336 20.8775 25.4284 12.8159 34.9998 13.003' stroke='black' stroke-width='3.3' />
                <path d='M35 12.9995L0.000244141 12.9995' stroke='black' stroke-width='3.3' />
              </svg>
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default Form;
